
import { Button, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Formik } from "formik";
import { useState ,useEffect} from "react";
import styles from "./CreateTrackID.module.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FetchAllGroupApiDataHandler from "../../../ProjectComponents/CreateProject/CreateProjectApi/FetchAllGroupApiDataHandler";
import FetchAllActiveProjectByGroupApiHandler from "./CreateTrackApi/FetchAllActiveProjectByGroupApi";
import FetchAllRequestTypeApiHandler from "./CreateTrackApi/FetchAllRequestTypeApiHandler";
import CreateTrackApiHandler from "./CreateTrackApi/CreateTrackApiHandler";
import FetchSingleTrackApiHandler from "./CreateTrackApi/FetchSingleTrackApi";

const CreateTrackID = ({setNextMailButton}) => {
  const [isValid, setIsValid] = useState(false);
  const [isProjectCreate, setIsProjectCreate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [status,setStatus] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [allGroupData, setAllGroupData] = useState([]);
  const [allProjectData, setAllProjectData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [unoccupiedTags, setUnoccupiedTags] = useState([]);

  const initialValues = {
    groupName: "",
    projectName: "",
    requestType: "",
    trackDetails: ""
  };

  const validate = (values) => {
    setIsValid(true);
    let errors = {};
    if (!values.groupName) {
        errors.groupName = "Group name is required";
        setIsValid(false);
      }
    if (!values.projectName) {
      errors.projectName = "Project name is required";
      setIsValid(false);
    }

    if (!values.requestType) {
      errors.requestType = "Request type is required";
      setIsValid(false);
    }

    return errors;
  };


  useEffect( () =>{
    const FetchOrganizationData = async() =>{
      const allData = await FetchAllGroupApiDataHandler(); 
      console.log(allData.body);
       if(allData.success === true)
       {
        setAllGroupData(await allData.body);
        console.log("data aise ")
           
       }
          
    }
    FetchOrganizationData();
  },[])

  useEffect( () =>{
    const FetchOrganizationData = async() =>{
      const allData = await FetchAllActiveProjectByGroupApiHandler(groupData); 
      console.log(allData.body);
       if(allData.success === true)
       {
        setAllProjectData(await allData.body);
        console.log("project data ")
           
       }
          
    }
    FetchOrganizationData();
  },[groupData])

  useEffect( () =>{
    const FetchOrganizationData = async() =>{
      const allData = await FetchAllRequestTypeApiHandler(); 
      console.log(allData.body);
       if(allData.success === true)
       {
        setRequestData(await allData.body);
        console.log("data aise ")
           
       }
          
    }
    FetchOrganizationData();
  },[])

  useEffect( () =>{
    const FetchSingleData = async() =>{
      const allData = await FetchSingleTrackApiHandler(); 
      console.log(allData.body);
       if(allData.success === true)
       {
        setUnoccupiedTags(await allData.body._id);
           
       }
          
    }
    FetchSingleData();
  },[])

  const onSubmit = async (values, { resetForm }) => {
    console.log("track value value",values);
    setStatus(true);
    const res = await CreateTrackApiHandler(values, unoccupiedTags);
    console.log("create project",res);
    if(res.success === true)
    {
      setIsProjectCreate(true);
      setIsError(false);
      resetForm();
      setNextMailButton(true);
    }
    else{
      setIsError(true);
      setIsProjectCreate(false);
    }

  };

  return (
    <>
     
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        return (
          <>
            
                 {isProjectCreate && (
                  <div className={styles["password-reset-email-success"]}>
                    Track ID create successfully
                  </div>
                )}
                {isError && (
                  <div className={styles["password-reset-email-error"]}>
                    Something went wrong !!!! 
                  </div>
                )}
                
                
                <h3 className={styles['group-header-center']}>Create Track ID</h3>
                <div className={styles["login-input-field"]}>

                <FormControl required className={styles["login-form"]} fullWidth>
                    <InputLabel id="organization-initial-label">Group Name</InputLabel>
                        {formik.values.groupName ? setGroupData(formik.values.groupName): null}
                        <Select
                              labelId="demo-simple-select-label"
                              id="groupName"
                              name="groupName"
                              value={formik.values.groupName}
                              label= "Group Name"
                              size="small"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.groupName &&
                                Boolean(formik.errors.groupName)
                              }
                            >
                            
                              {allGroupData && allGroupData.map((groupData) => (
                                <MenuItem
                                  key={groupData._id}
                                  value={groupData._id}
                                >
                                  {groupData.name_en}
                                </MenuItem>
                              ))}
                              
                              
                            </Select>
                            <span className={styles["error"]}>
                              {formik.touched.groupName &&
                              formik.errors.groupName ? (
                                <div>{formik.errors.groupName}</div>
                              ) : null}
                            </span>
                        
                

                    <br />
                  </FormControl>
                  
                  <FormControl required className={styles["login-form"]} fullWidth>
                    <InputLabel id="organization-initial-label">Project Name</InputLabel>
                       
                        <Select
                              labelId="demo-simple-select-label"
                              id="projectName"
                              name="projectName"
                              value={formik.values.projectName}
                              label= "Project Name"
                              size="small"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.projectName &&
                                Boolean(formik.errors.projectName)
                              }
                            >
                            
                              {allProjectData && allProjectData.map((projectData) => (
                                <MenuItem
                                  key={projectData._id}
                                  value={projectData._id}
                                >
                                  {projectData.name_en}
                                </MenuItem>
                              ))}
                             
                              
                            </Select>
                            <span className={styles["error"]}>
                              {formik.touched.projectName &&
                              formik.errors.projectName ? (
                                <div>{formik.errors.projectName}</div>
                              ) : null}
                            </span>
                        
                

                    <br />
                  </FormControl>

                  <FormControl required className={styles["login-form"]} fullWidth>
                    <InputLabel id="organization-initial-label">Request Type</InputLabel>
                       
                        <Select
                              labelId="demo-simple-select-label"
                              id="requestType"
                              name="requestType"
                              value={formik.values.requestType}
                              label= "Request Type"
                              size="small"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.requestType &&
                                Boolean(formik.errors.requestType)
                              }
                            >
                            
                              {requestData && requestData.map((row) => (
                                <MenuItem
                                  key={row._id}
                                  value={row._id}
                                >
                                  {row.title_en}
                                </MenuItem>
                              ))}
                              
                              
                            </Select>
                            <span className={styles["error"]}>
                              {formik.touched.requestType &&
                              formik.errors.requestType ? (
                                <div>{formik.errors.requestType}</div>
                              ) : null}
                            </span>
                        
                

                    <br />
                  </FormControl>
                  
                  <FormControl className={styles["login-form"]} fullWidth>
                <div className="control">
                  <TextareaAutosize
                    id="trackDetails"
                    name="trackDetails"
                    className="input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.trackDetails}
                    placeholder="Track Details"
                    style={{ width: '99%', maxWidth: '100%',minWidth:'97%', height:140, maxHeight:170 , minHeight:140,
                             fontSize:'17px', fontWeight:"none", textIndent:'10px',border:'1px solid gray', borderRadius:'5px'}}
                  />
                </div>
              
              <br />
                  </FormControl>
                  
                </div>
                <Stack justifyContent="center">
                  <Button
                    type="Submit"
                    variant="contained"
                   
                    onClick={formik.handleSubmit}
                   
                  >
                   Create
                  </Button>
                </Stack>
              
          </>
        );
      }}
    </Formik>
    </>
  );
};

export default CreateTrackID;

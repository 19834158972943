import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaHome
} from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { RiDashboardLine, RiUser2Line } from "react-icons/ri";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader
} from "react-pro-sidebar";
import { useNavigate, useLocation } from "react-router-dom";

import "react-pro-sidebar/dist/css/styles.css";
import styles from "./SidebarLayout.module.css";

const SidebarLayout = () => {

  const [collapsedSidebar, setCollapsedSidebar] = useState(false);
  const location = useLocation()
  const navigate = useNavigate();
  const permissionToken = JSON.parse(localStorage.getItem('permissions'));
  const [selectedItem, setSelectedItem] = useState(location.pathname);
  console.log("permission token", permissionToken)
  console.log(selectedItem);
  const matches = useMediaQuery("(max-width:1150px)");
  console.log(matches);
  useEffect(() => {
    if (matches) {
      setCollapsedSidebar(true);
    } else {
      setCollapsedSidebar(false);
    }
  }, [matches]);

  const logout = async () => {
    localStorage.removeItem('access_token');
    navigate('/')
  };

  return (
    <>
      <div id="sidebar">
        <ProSidebar
          style={{ backgroundColor: "#fff" }}
          collapsed={collapsedSidebar}
        >
          <div className={styles["sidebar-header-wrapper"]}>
            <SidebarHeader onClick={() => setCollapsedSidebar((prev) => !prev)}>
              <h4 className={styles[`sideabar-header`]}>ETTMS</h4>
              <h2 className={styles[`sidebar-toggle-btn`]}>
                {collapsedSidebar === true ? (
                  <FaArrowCircleRight />
                ) : (
                  <FaArrowCircleLeft />
                )}
              </h2>
            </SidebarHeader>
          </div>

          <SidebarContent className={styles["sidebar-content"]}>
            <Menu iconShape="circle" popperArrow={true}>

              <div
                className={
                  selectedItem === "/home"
                    ? `${styles[`active-side-menu`]}`
                    : null
                }
              >
                <Tooltip
                  title="Home"
                  placement="right"
                  arrow
                  PopperProps={{
                    disablePortal: !collapsedSidebar,
                  }}
                >
                  <MenuItem
                    icon={<FaHome className={styles[`sidebar-menu-icon`]} />}
                    onClick={
                      () => navigate("/home")
                    }
                  >

                    Home
                  </MenuItem>
                </Tooltip>
              </div>

              <div
                className={
                  selectedItem === "/qrCodeReader"
                    ? `${styles[`active-side-menu`]}`
                    : null
                }
              >
                <Tooltip
                  title="QR Code Reader"
                  placement="right"
                  arrow
                  PopperProps={{
                    disablePortal: !collapsedSidebar,
                  }}
                >
                  <MenuItem
                    icon={<FaHome className={styles[`sidebar-menu-icon`]} />}
                    onClick={
                      () => navigate("/qrCodeReader")
                    }
                  >

                    QR Code Reader
                  </MenuItem>
                </Tooltip>
              </div>
              {permissionToken.map(row => row._id === "63c8e4f57128e66bb0fc51a1" ?


                <div
                  className={
                    selectedItem === "/superAdminDashboard"
                      ? `${styles[`active-side-menu`]}`
                      : null
                  }
                >
                  <Tooltip
                    title="Super Admin Dashboard"
                    placement="right"
                    arrow
                    PopperProps={{
                      disablePortal: !collapsedSidebar,
                    }}
                  >
                    <MenuItem
                      icon={
                        <RiDashboardLine className={styles[`sidebar-menu-icon`]} />
                      }
                      onClick={
                        () => navigate("/superAdminDashboard")
                      }
                    >
                      Super Admin Dashboard
                    </MenuItem>
                  </Tooltip>
                </div>
                : row._id === "63c8e5047128e66bb0fc51a3" ?
                  <div
                    className={
                      selectedItem === "/adminDashboard"
                        ? `${styles[`active-side-menu`]}`
                        : null
                    }
                  >
                    <Tooltip
                      title="Admin Dashboard"
                      placement="right"
                      arrow
                      PopperProps={{
                        disablePortal: !collapsedSidebar,
                      }}
                    >
                      <MenuItem
                        icon={
                          <RiDashboardLine className={styles[`sidebar-menu-icon`]} />
                        }
                        onClick={
                          () => navigate("/adminDashboard")
                        }
                      >

                        Admin Dashboard
                      </MenuItem>
                    </Tooltip>
                  </div>
                  : row._id === "63c8e5117128e66bb0fc51a5" ?
                    <div>
                      <div
                        className={
                          selectedItem === "/userDashboard"
                            ? `${styles[`active-side-menu`]}`
                            : null
                        }
                      >
                        <Tooltip
                          title="User Dashboard"
                          placement="right"
                          arrow
                          PopperProps={{
                            disablePortal: !collapsedSidebar,
                          }}
                        >
                          <MenuItem
                            icon={
                              <RiDashboardLine className={styles[`sidebar-menu-icon`]} />
                            }
                            onClick={
                              () => navigate("/userDashboard")
                            }
                          >

                            User Dashboard
                          </MenuItem>
                        </Tooltip>
                      </div>

                      <div
                        className={
                          selectedItem === "/handover"
                            ? `${styles[`active-side-menu`]}`
                            : null
                        }
                      >
                        <Tooltip
                          title="Handover"
                          placement="right"
                          arrow
                          PopperProps={{
                            disablePortal: !collapsedSidebar,
                          }}
                        >
                          <MenuItem
                            icon={
                              <RiDashboardLine className={styles[`sidebar-menu-icon`]} />
                            }
                            onClick={
                              () => navigate("/handover")
                            }
                          >

                            HandOver
                          </MenuItem>
                        </Tooltip>
                      </div>
                      <div
                        className={
                          selectedItem === "/profile"
                            ? `${styles[`active-side-menu`]}`
                            : null
                        }
                      >
                        <Tooltip
                          title="Profile"
                          placement="right"
                          arrow
                          PopperProps={{
                            disablePortal: !collapsedSidebar,
                          }}
                        >
                          <MenuItem
                            icon={
                              <RiUser2Line className={styles[`sidebar-menu-icon`]} />
                            }
                            onClick={
                              () => navigate("/profile")
                            }
                          >
                            Profile
                          </MenuItem>
                        </Tooltip>
                      </div>
                    </div>
                    : null
              )}
            </Menu>
          </SidebarContent>

          <SidebarFooter className={styles[`sidebar-footer`]}>
            <Menu
              iconShape="square"
              className={
                collapsedSidebar === false
                  ? [
                    styles["sidebar-footer-menu"],
                    styles["sidebar-footer-menu-open"],
                  ].join(" ")
                  : styles["sidebar-footer-menu"]
              }
            >
              <Tooltip
                title="Log out"
                placement="top"
                arrow
                PopperProps={{
                  disablePortal: false,
                }}
              >
                <span onClick={() => logout()}>
                  <MenuItem icon={<FiLogOut />}>LOG OUT</MenuItem>
                </span>
              </Tooltip>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  )
}

export default SidebarLayout
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AdminUserTable from "../AdminUserTable";
import AdminPermissionTable from "../AdminPermissionTable";
import styles from './AdminTab.module.css';
import GroupModal from "../../../GroupComponents/GroupModal";
import UserModal from "../../../UserComponents/UserModal";
import PermissionModal from "../../../PermissionComponents/PermissionModal";
import AdminGroupTable from "../AdminGroupTable";
import AdminCollaborationTable from "../AdminCollaborationTable";
import CollaborationModal from "../../../HandoverComponents/Collaboration/CollaborationModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const AdminTab = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="admin tabs"
          >
            <Tab label="Group" {...a11yProps(0)} />
            <Tab label="Permission" {...a11yProps(1)} />
            <Tab label="User" {...a11yProps(2)} />
            <Tab label="Collaboration" {...a11yProps(3)} />
            <Box flexGrow={1} />
            {value === 0 ?
              <GroupModal />
              : value === 1 ?
                <PermissionModal />
                : value === 2 ?
                  <UserModal />
                  : <CollaborationModal />
            }
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AdminGroupTable />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdminPermissionTable />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AdminUserTable />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AdminCollaborationTable />
        </TabPanel>

      </Box>

    </>
  )
}

export default AdminTab;

const FetchAllPermissionListApiHandler = async () => {
    const accessToken = localStorage.getItem('access_token');
    console.log(accessToken);
    
     const res = await fetch(`${process.env.REACT_APP_BASE_URL+"permissions/viewAllPermissions"}`, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
         "Authorization": `Bearer ${accessToken}`,
       },
       body: JSON.stringify({
         
       }),
       
     });
    
     const data = await res.json();
     return data;
   
 
 }
 
 export default FetchAllPermissionListApiHandler
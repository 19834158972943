
const FetchSingleDeviceInfoByQRCodeApi = async (id) => {
    const accessToken = localStorage.getItem('access_token');
    console.log(accessToken);
   
    const res = await fetch(`${process.env.REACT_APP_BASE_URL+"devices/showDeviceDetailsByDevicetag"}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        device_tag: id
      }),
      
    });
   
    const data = await res.json();
    console.log(data)
    return data;

}

export default FetchSingleDeviceInfoByQRCodeApi

import { Button, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import { useState ,useEffect} from "react";
import styles from "./DeviceInformationRegistration.module.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import DeviceInformationRegistrationApiHandler from "../DeviceApiHandler/DeviceInformationRegistrationApiHandler";
import FetchAllDeviceTypeApiHandler from "../DeviceApiHandler/FetchAllDeviceTypeApiHandler";

const DeviceInformationRegistration = ({setNextFinishButton, id}) => {
  
  const [isValid, setIsValid] = useState(false);
  const [isProjectCreate, setIsProjectCreate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [status,setStatus] = useState(false);
  const [allDeviceData, setAllDeviceData] = useState([]);

  const initialValues = {
    deviceType: "",
    vendor: "",
    modelNo: "",
    serial: "",
    spacification: "",
    state: "",
    comment: ""
  };

  const validate = (values) => {
    setIsValid(true);
    let errors = {};
    if (!values.deviceType) {
      errors.deviceType = "Device type is required";
      setIsValid(false);
    }
    if (!values.vendor) {
      errors.vendor = "Vendor name is required";
      setIsValid(false);
    }

    if (!values.modelNo) {
      errors.modelNo = "Model number is required";
      setIsValid(false);
    }
    if (!values.serial) {
      errors.serial = "Serial number is required";
      setIsValid(false);
    }

    return errors;
  };

  
  useEffect( () =>{
    const FetchDeviceTypeData = async() =>{
      const allData = await FetchAllDeviceTypeApiHandler(); 
      console.log(allData.body);
       if(allData.success === true)
       {
        setAllDeviceData(await allData.body);
           
       }
          
    }
    FetchDeviceTypeData();
  },[])
 
  const onSubmit = async (values, { resetForm }) => {
    console.log("device information value",values);
    setStatus(true);
    setNextFinishButton(true);
    const res = await DeviceInformationRegistrationApiHandler(values,id);
    console.log("create project",res);
    if(res.success === true)
    {
      setIsProjectCreate(true);
      setIsError(false);
      resetForm();
    }
    else{
      setIsError(true);
      setIsProjectCreate(false);
    }
  };

  return (
    <>
     
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        return (
          <>
           
                 {isProjectCreate && (
                  <div className={styles["password-reset-email-success"]}>
                    Device registration successful
                  </div>
                )}
                {isError && (
                  <div className={styles["password-reset-email-error"]}>
                    Device already exists
                  </div>
                )}
                
                
                <h3 className={styles['group-header-center']}>Device Information Registration</h3>
                <div className={styles["login-input-field"]}>
                  
                  
                  <div className={styles['topMarginForMaker']}>
                  <FormControl required className={styles["login-form"]} fullWidth>
                    <InputLabel id="organization-initial-label">Device Type</InputLabel>
                        
                        <Select
                              labelId="demo-simple-select-label"
                              id="deviceType"
                              name="deviceType"
                              value={formik.values.deviceType}
                              label= "Device Type"
                              size="small"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.deviceType &&
                                Boolean(formik.errors.deviceType)
                              }
                            >
                            
                              {allDeviceData && allDeviceData.map((deviceData) => (
                                <MenuItem
                                  key={deviceData._id}
                                  value={deviceData._id}
                                >
                                  {deviceData.name_en}
                                </MenuItem>
                              ))}
                             
                              
                            </Select>
                            <span className={styles["error"]}>
                              {formik.touched.deviceType &&
                              formik.errors.deviceType ? (
                                <div>{formik.errors.deviceType}</div>
                              ) : null}
                            </span>
                        
                

                    <br />
                  </FormControl>
                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="vendor"
                      name="vendor"
                      label= "Enter vendor Name"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.vendor}
                      error={
                        formik.touched.vendor &&
                        Boolean(formik.errors.vendor)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.vendor && formik.errors.vendor ? (
                        <div>{formik.errors.vendor}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>
                  </div>
                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="modelNo"
                      name="modelNo"
                      label= "Enter Model Number"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.modelNo}
                      error={
                        formik.touched.modelNo &&
                        Boolean(formik.errors.modelNo)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.modelNo && formik.errors.modelNo ? (
                        <div>{formik.errors.modelNo}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>
                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="serial"
                      name="serial"
                      label= "Enter Serial Number"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.serial}
                      error={
                        formik.touched.serial &&
                        Boolean(formik.errors.serial)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.serial && formik.errors.serial ? (
                        <div>{formik.errors.serial}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>

                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="spacification"
                      name="spacification"
                      label= "Enter Spacification"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.spacification}
                      error={
                        formik.touched.spacification &&
                        Boolean(formik.errors.spacification)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.spacification && formik.errors.spacification ? (
                        <div>{formik.errors.spacification}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>
                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="state"
                      name="state"
                      label= "Enter State"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                      error={
                        formik.touched.state &&
                        Boolean(formik.errors.state)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.state && formik.errors.state ? (
                        <div>{formik.errors.state}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>

                  <FormControl className={styles["login-form"]} fullWidth>
                <div className="control">
                  <TextareaAutosize
                    id="comment"
                    name="comment"
                    className="input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    placeholder="Enter Comment"
                    style={{ width: '99%', maxWidth: '100%',minWidth:'97%', height:70, maxHeight:70 , minHeight:70,
                             fontSize:'17px', fontWeight:"none", textIndent:'10px',border:'1px solid gray', borderRadius:'5px'}}
                  />
                </div>
              
              <br />
                  </FormControl>
                  
                
                </div>
                <Stack justifyContent="center">
                  <Button
                    type="Submit"
                    variant="contained"
                    
                    onClick={formik.handleSubmit}
                   
                  >
                   Create
                  </Button>
                </Stack>
              
          </>
        );
      }}
    </Formik>
    </>
  );
};

export default  DeviceInformationRegistration;


import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import styles from "./SelectOrganizationModal.module.css";
import CloseIcon from '@mui/icons-material/Close';
import SelectOrganization from "../SelectOrganization";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const SelectOrganizationModal = (props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [isEmailSended, setIsEmailSended] = useState(false);
    const [isError, setIsError] = useState(false);
    const handleClose = () => {
        setIsEmailSended(false);
        setIsError(false);
        setOpen(false);

    };


    return (

        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.closeButton}>
                        <Button onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                    </div>
                    {/* <CreateUser /> */}
                    {/* <p>{props.Previous_handover_id === null ? "null" : "undefikd"}</p> */}
                    <SelectOrganization previous_handover_id={props.Previous_handover_id} handover_id={props.handover_id} />

                </Box>
            </Modal>
            <div className={styles["organization-button-control"]}>
                <Button variant="contained" onClick={handleOpen} >View</Button>
            </div>

        </>


    );
};

export default SelectOrganizationModal

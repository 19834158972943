
import { Button, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import { useState } from "react";
import styles from "./CreateGroup.module.css";
import CreateGroupApiHandler from "./GroupApiHandler/CreateGroupApiHandler";


const CreateGroup = () => {
  const [isValid, setIsValid] = useState(false);
  const [isGroupCreate, setIsGroupCreate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [status,setStatus] = useState(false);


  const initialValues = {
    groupNameEn: "",
    groupNameJp: "",
  };

  const validate = (values) => {
    setIsValid(true);
    let errors = {};

    if (!values.groupNameEn) {
      errors.groupNameEn = "Group name is required";
      setIsValid(false);
    }
    if (!values.groupNameJp) {
      errors.groupNameJp = "Group name is required";
      setIsValid(false);
    }


    return errors;
  };


  const onSubmit = async (values, { resetForm }) => {
    console.log(values);
    setStatus(true);
    const res = await CreateGroupApiHandler(values);
    console.log(res);
    if(res.success === true)
    {
      setIsGroupCreate(true);
      setIsError(false);
      resetForm();
    }
    else{
      setIsError(true);
      setIsGroupCreate(false);
    }
    
  };

  return (
    <>
      
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        return (
          <>
                 {isGroupCreate && (
                  <div className={styles["password-reset-email-success"]}>
                    Group create successfully
                  </div>
                )}
                {isError && (
                  <div className={styles["password-reset-email-error"]}>
                    Group already exists
                  </div>
                )}
                
                <h3 className={styles['group-header-center']}>Create Group</h3>
                <div className={styles["login-input-field"]}>
                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="groupNameEn"
                      name="groupNameEn"
                      label= "Group Name English"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.groupNameEn}
                      error={
                        formik.touched.groupNameEn &&
                        Boolean(formik.errors.groupNameEn)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.groupNameEn && formik.errors.groupNameEn ? (
                        <div>{formik.errors.groupNameEn}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>
                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="groupNameJp"
                      name="groupNameJp"
                      label= "Group Name Japanese"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.groupNameJp}
                      error={
                        formik.touched.groupNameJp &&
                        Boolean(formik.errors.groupNameJp)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.groupNameJp && formik.errors.groupNameJp ? (
                        <div>{formik.errors.groupNameJp}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>
                  
                </div>
                <Stack justifyContent="center">
                  <Button
                    type="Submit"
                    variant="contained"
                    onClick={formik.handleSubmit}
                   
                  >
                   Create
                  </Button>
                </Stack>
              
          </>
        );
      }}
    </Formik>
    </>
  );
};

export default CreateGroup;


import { Button, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import { useState } from "react";
import styles from "./ReportForm.module.css";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ReportRecordApiHandler from "../ReportApi/ReportRecordApiHandler";

const ReportForm = (props) => {

  const [isValid, setIsValid] = useState(false);
  const [isProjectCreate, setIsProjectCreate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [status,setStatus] = useState(false);


  const initialValues = {
    reportId: "",
    reportDetails: ""
  };

  const validate = (values) => {
    setIsValid(true);
    let errors = {};
    if (!values.reportDetails) {
        errors.reportDetails = "Report details is required";
        setIsValid(false);
      }

    return errors;
  };


  const onSubmit = async (values, { resetForm }) => {
    console.log("track value value",values);
    console.log("track value value",props.reportId);
    setStatus(true);
    const res = await ReportRecordApiHandler(props.reportId, values);
    console.log("create project",res);
    if(res.success === true)
    {
      setIsProjectCreate(true);
      setIsError(false);
      resetForm();
    }
    else{
      setIsError(true);
      setIsProjectCreate(false);
    }

    
  };

  return (
    <>
      
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        return (
          <>
                 {isProjectCreate && (
                  <div className={styles["password-reset-email-success"]}>
                    Reported Successfully 
                  </div>
                )}
                {isError && (
                  <div className={styles["password-reset-email-error"]}>
                    Something went wrong !!!! 
                  </div>
                )}
                
                <h3 className={styles['group-header-center']}>Report Track Record</h3>
                <div className={styles["login-input-field"]}>

                  <FormControl className={styles["login-form"]} fullWidth>
                      <TextField
                        id="reportId"
                        name="reportId"
                        label="Report ID"
                        variant="outlined"
                        size="small"
                        helperText= "Reported Id for track device details"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value= {props.reportId}
                        defaultValue= '0'
                        InputProps={{
                            readOnly: true,
                          }}
                        error={
                          formik.touched.reportId && Boolean(formik.errors.reportId)
                        }
                      />
                      <span className={styles["error"]}>
                        {formik.touched.reportId && formik.errors.reportId ? (
                          <div>{formik.errors.reportId}</div>
                        ) : null}
                      </span>
                      <br />
                    </FormControl>
                  
                  <FormControl className={styles["login-form"]} fullWidth>
                <div className="control">
                  <TextareaAutosize
                    id="reportDetails"
                    name="reportDetails"
                    className="input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.reportDetails}
                    placeholder="Report Details"
                    style={{ 
                        width: '99%',
                        maxWidth: '100%',
                        height:50,
                        maxHeight:100,
                        fontSize:'15px',
                        fontWeight:"none",
                        border: '1px solid gray',
                        textIndent:'10px',
                        
                    }}
                    error={
                        formik.touched.reportDetails && Boolean(formik.errors.reportDetails)
                      }
                  />
                  <span className={styles["error"]}>
                        {formik.touched.reportDetails && formik.errors.reportDetails ? (
                          <div>{formik.errors.reportDetails}</div>
                        ) : null}
                      </span>
                </div>
              
              <br />
                  </FormControl>
                </div>
                <Stack justifyContent="center">
                  <Button
                    type="Submit"
                    variant="contained"
                    
                    onClick={formik.handleSubmit}
                   
                  >
                   Report
                  </Button>
                </Stack>
          </>
        );
      }}
    </Formik>
    </>
  );
};

export default ReportForm;

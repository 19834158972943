import React, { useState } from 'react'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import HandoverAcceptApi from '../../HandoverApi/HandoverAcceptApi';
import styles from "./TrackDetails.module.css";
import HandoverRejectApi from '../../HandoverApi/HandoverRejectApi';
import HandoverReturnApi from '../../HandoverApi/HandoverReturnApi';

const TrackDetails = (props) => {
    const [acceptHandover, setAcceptHandover] = useState(false)
    const [rejectHandover, setRejectHandover] = useState(false)
    const [returnHandover, setReturnHandover] = useState(false)
    const [isError, setIsError] = useState(false)

    const AcceptedHandover = async (id) => {
        const res = await HandoverAcceptApi(id)
        console.log("accepted handover response", res);
        if (res.success === true) {
            setAcceptHandover(true);
            setIsError(false);
        }
        else {
            setIsError(true);
            setAcceptHandover(false);
        }

    }

    const RejectedHandover = async (id) => {
        const res = await HandoverRejectApi(id)
        console.log("reject handover response", res);
        if (res.success === true) {
            setRejectHandover(true);
            setIsError(false);
        }
        else {
            setIsError(true);
            setRejectHandover(false);
        }

    }

    const ReturnHandover = async (id) => {
        const res = await HandoverReturnApi(id)
        console.log("return handover response", res);
        if (res.success === true) {
            setReturnHandover(true);
            setIsError(false);
        }
        else {
            setIsError(true);
            setReturnHandover(false);
        }

    }

    return (
        <>
            {/* <h1>{props.handover_id}</h1> */}
            {acceptHandover && (
                <div className={styles["password-reset-email-success"]}>
                    Accepted Handover Request
                </div>
            )}
            {rejectHandover && (
                <div className={styles["password-reset-email-success"]}>
                    Rejected Handover Request
                </div>
            )}
            {returnHandover && (
                <div className={styles["password-reset-email-success"]}>
                    Return Handover Request
                </div>
            )}
            {isError && (
                <div className={styles["password-reset-email-error"]}>
                    Something went wrong !!!!
                </div>
            )}
            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" marginTop={3}>
                <Button variant="contained" onClick={() => AcceptedHandover(props.handover_id)}>Accept</Button>
                <Button variant="contained" onClick={() => RejectedHandover(props.handover_id)}>Reject</Button>
                {/* <Button variant="contained">Handover</Button> */}
                <Button variant="contained" onClick={() => ReturnHandover(props.handover_id)}>Return</Button>
            </Stack>
        </>
    )
}

export default TrackDetails
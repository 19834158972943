const HandoverRejectApi = async (id) => {
    const accessToken = localStorage.getItem('access_token')

    const res = await fetch(`${process.env.REACT_APP_BASE_URL + "handovers/rejectHandover"}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Authorization": `Bearer ${accessToken}`,
        },

        body: JSON.stringify({
            handover_id: id
           
        })
    });

    const data = await res.json();
    return data;
}

export default HandoverRejectApi
import React,{useEffect} from 'react'
import styles from "./SingleDeviceInfoShow.module.css"
import { useNavigate , useLocation} from 'react-router-dom'
import Layout from '../../../../CommonComponents/Layout';
import RecordInfoShowTable from './RecordInfoShowTable';
import DeviceInfoShowTable from './DeviceInfoShowTable';


const SingleDeviceInfoShow = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const { id } = state; 
  console.log(id);
  useEffect(()=>{

    if(!localStorage.getItem('access_token'))
    {
        navigate('/')
    }
  },[navigate])
  return (
    <>
    <Layout>
    <div className={styles[`adminDashboard-wrapper`]}>
      <div>
        
      </div>
    <div className={styles["adminDashboardTable-wraper"]}>
     
     <DeviceInfoShowTable id={id}/>
     <div className={styles["LayoutForMarginTop"]}>
     <RecordInfoShowTable id={id}/>
     </div>

    </div>
    </div>
    </Layout>
    </>
  )
}

export default SingleDeviceInfoShow
const ShowSendHandoverApi = async () => {
    const accessToken = localStorage.getItem('access_token');
    console.log(accessToken);
    const res = await fetch(`${process.env.REACT_APP_BASE_URL+"handovers/handoverRequestFromMyOrganization"}`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Authorization": `Bearer ${accessToken}`,
        },
    });

    const data = await res.json();
    console.log("Send handover from my orgs are " + data)
    return data;

}

export default ShowSendHandoverApi
import React, { useState, useRef, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styles from './UserProjectBasedOnGroup.module.css';
import ShowAllProjectBasedOnGroupApiHandler from '../UserDashboardApiHandler/ShowAllProjectBasedOnGroupApi';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import Papa from 'papaparse';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PDFDownloadLink } from "@react-pdf/renderer";
import SummaryPdfDownload from './Template/SummaryPdf';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import QrCodePdfDownload from './Template/QrCodePdfDownload';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

const localDateConvert = (D) => {
  return new Date(D).toLocaleString();
}

const UserProjectBasedOnGroup = (props) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dashboardData, setDashboardData] = useState([]);
  const [trackDeviceData, setTrackDeviceData] = useState({
    'notStartedTracks': 0,
    'startedTracks': 0,
    'endedTracks': 0,
    'devices': 0
  });
  const [projectData, setProjectData] = useState([]);
  console.log(dashboardData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  console.log("table click value", props.clickGroupId)
  console.log("table click data", projectData)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [summaryPdfData, setSummaryPdfData] = useState(null);
  const btnSummaryPDFAuto = useRef(null);
  let isSummayButtonClicked = false;
  const [rendereSummary_link, setRendereSummary_link] = useState(false);

  const [pdfData, setPdfData] = useState(null);
  const [renderePDF_link, setRenderePDF_link] = useState(false);
  const btnQrPDFAuto = useRef(null);
  let isPdfButtonClicked = false;

  const [projectName, setProjectName] = useState(null);
  // const [projectId, setProjectId] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenDialog = async (id) => {
    await GetTrackDeviceDataById(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCSV = async (projectName, projectId) => {
    // Make a GET request to the API
    const accessToken = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_BASE_URL + "tracks/showAllActiveDevicesOfFollowingProject"}`, {
      method: 'POST',
      body: JSON.stringify({
        project_id: projectId
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });
    if (!response) { return }
    const data = await response.json();
    if (projectId !== null || projectId !== "" || typeof projectId !== "undefined") {
      // Convert the data into an array of objects
      const rows = data?.body?.map(item => {
        return {
          TrackID: item.device_tag.substring(0, item.device_tag.indexOf(".")),
          DeviceVendor: item.vendor,
          DeviceModel: item.model,
          DeviceSerial: item.serial,
          Memo: item.state
        };
      });
      // Convert the array of objects into a CSV string
      const csv = Papa.unparse(rows);
      // Create a URL that points to the CSV string
      const url = window.URL.createObjectURL(new Blob([csv]));
      // Create a link that allows the user to download the CSV file
      const link = document.createElement('a');
      link.href = url;
      link.download = `${projectName}_TRACKID_LIST_${Date.now()}.csv`;
      link.click();
    } else { return }
  }

  const handleQrCodePdf = async (projectName, projectId) => {
    console.log('__handleQrCodePdf starts');
    setProjectName(projectName);
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${process.env.REACT_APP_BASE_URL + "tracks/showAllActiveTracksOfFollowingProject"}`, {
      method: 'POST',
      body: JSON.stringify({
        project_id: projectId
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });
    if (!response) { return }
    const _pdfData = await response.json();
    console.log('__handleQrCodePdf data', _pdfData);
    setPdfData(_pdfData?.body);
    setRenderePDF_link((prevvalue) => {
      setLoading(true)
      setTimeout(() => {
        if (!isPdfButtonClicked) {
          btnQrPDFAuto.current.click();
          isPdfButtonClicked = !isPdfButtonClicked;
          setTimeout(() => {
            setRenderePDF_link((prevvalue) => {
              if (prevvalue) {
                isPdfButtonClicked = false;
                return false;
              }
            });
          }, 3000);
        }
      }, 3000)
      setLoading(false)
      return true;
    });
    console.log('__handleQrCodePdf ends');
  }

  const handleSummaryPdfDownload = async (projectName, projectId) => {
    //console.log('__handleSummaryPdfDownload starts');

    setProjectName(projectName);
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${process.env.REACT_APP_BASE_URL + "tracks/showAllActiveDevicesOfFollowingProject"}`, {
      method: 'POST',
      body: JSON.stringify({
        project_id: projectId
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });
    if (!response) { return }

    const _pdfData = await response.json();
    //console.log('__handleSummaryPdfDownload data',_pdfData);
    setSummaryPdfData(_pdfData?.body);

    setRendereSummary_link((prevvalue) => {
      setTimeout(() => {
        if (!isSummayButtonClicked) {
          btnSummaryPDFAuto.current.click();
          isSummayButtonClicked = !isSummayButtonClicked;
          setTimeout(() => {
            setRendereSummary_link((prevvalue) => {
              if (prevvalue) {
                isSummayButtonClicked = false;
                return false;
              }
            });
          }, 3000);
        }
      }, 3000)
      return true;
    });
    //console.log('__handleSummaryPdfDownload ends');
  }

  const GetTrackDeviceDataById = async (id) => {
    const accessToken = localStorage.getItem('access_token');
    const FetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL + "tracks/showProjectsTrackDetailsCount"}`, {
        method: 'POST',
        body: JSON.stringify({
          project_id: id
        }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });
      const detailData = await response.json();
      //console.log("__detailData", detailData);
      setTrackDeviceData(detailData.body);
      //console.log("__trackDeviceData", trackDeviceData);
    }
    FetchData();
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      navigate('/')
    }
    const FetchProjectData = async () => {
      const allData = await ShowAllProjectBasedOnGroupApiHandler(props.clickGroupId);
      console.log(allData.body);
      if (allData.success === true) {
        setProjectData(await allData.body);
        console.log("data aise ")

      }
    }
    FetchProjectData();
  }, [props.clickGroupId])


  return (
    <>
      {
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}>
          <CircularProgress color="inherit" disableShrink />
          <Typography sx={{ ml: 3 }}>Loading. Please wait</Typography>
        </Backdrop>
      }
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>

        <TableContainer sx={{ maxHeight: 345 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Details</TableCell>
                <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Project Name</TableCell>
                <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Created At</TableCell>
                <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">CSV</TableCell>
                {/* <TableCell  style={{backgroundColor: ' rgb(203, 200, 200)', fontWeight:'bold'}} align="center">QRCode</TableCell> */}
                <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Project Summary</TableCell>
                <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">View TrackBoard</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectData.map((row) => (
                <TableRow
                  key={row.projectName}
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                >
                  <Dialog
                    fullScreen={false}
                    onClose={handleCloseDialog}
                    open={openDialog}
                    BackdropProps={{ style: { backgroundColor: "transparent" } }}>
                    <DialogTitle>Detail Information</DialogTitle>
                    <Table>
                      <TableHead>
                        <TableRow align='center' style={{ background: "#009688" }}>
                          <TableCell align='center' style={{ fontWeight: 'bold' }}>Total Track ID</TableCell>
                          <TableCell align='center' style={{ fontWeight: 'bold' }}>Track Not Started</TableCell>
                          <TableCell align='center' style={{ fontWeight: 'bold' }}>Track Started</TableCell>
                          <TableCell align="center" style={{ fontWeight: 'bold' }}>Track Ended</TableCell>
                          <TableCell align="center" style={{ fontWeight: 'bold' }}>Total Device ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align='center'>{trackDeviceData.totalTracks === "" ? "null" : trackDeviceData.totalTracks}</TableCell>
                          <TableCell align="center">{trackDeviceData.notStartedTracks === "" ? "null" : trackDeviceData.notStartedTracks}</TableCell>
                          <TableCell align="center">{trackDeviceData.startedTracks === "" ? "null" : trackDeviceData.startedTracks}</TableCell>
                          <TableCell align="center">{trackDeviceData.endedTracks === "" ? "null" : trackDeviceData.endedTracks}</TableCell>
                          <TableCell align="center">{trackDeviceData.devices === "" ? "null" : trackDeviceData.devices}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <DialogActions>
                      <Button autoFocus onClick={handleCloseDialog}>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <TableCell align="center">
                    <Tooltip title="Track & Device Information">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={async () => handleOpenDialog(row._id)}>
                        <PanToolAltIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ display: 'none' }}>{row._id}</TableCell>
                  <TableCell align="center">{row.name_en}</TableCell>
                  <TableCell align="center">{localDateConvert(row.createdAt)}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      value={row._id}
                      onClick={() => { handleCSV(row.name_en, row._id) }}>
                      <ListAltIcon />
                    </Button>
                  </TableCell>
                  {/* <TableCell align="center">
                <Button
                variant="contained"
                value={row._id}
                onClick={() => {handleQrCodePdf(row.name_en, row._id)}}>
                  <PictureAsPdfIcon />
                </Button>
                {
                  renderePDF_link && 
                    <PDFDownloadLink
                    document={<QrCodePdfDownload pdfData={pdfData} projectName={`${projectName}`}/>}
                    fileName={`${projectName}_QR_LIST_${Date.now()}.pdf`}
                    >
                      {
                        <Button ref={btnQrPDFAuto}></Button>
                      }
                    </PDFDownloadLink>
                }
              </TableCell> */}
                  <TableCell align="center">
                    <Stack justifyContent="center" spacing={2} direction="row">
                      <Button
                        variant="contained"
                        onClick={() => { handleSummaryPdfDownload(row.name_en, row._id) }}>
                        Download
                      </Button>
                      {
                        rendereSummary_link &&
                        <div>
                          <PDFDownloadLink
                            document={<SummaryPdfDownload summaryPdfData={summaryPdfData} projectName={`${projectName}`} />}
                            fileName={`${projectName}_PROJECT_SUMMARY_${Date.now()}.pdf`}>
                            {
                              <Button ref={btnSummaryPDFAuto}></Button>
                            }
                          </PDFDownloadLink>
                        </div>
                      }
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Stack justifyContent="center" spacing={2} direction="row">
                      <Button variant="contained" onClick={
                        () => navigate("/trackBoard", { state: { trackId: row._id } })
                      }>Visit</Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dashboardData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

export default UserProjectBasedOnGroup



import React, { useState}from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";

Font.register({
  family: 'Noto Sans JP',
  src: 'https://cdn.jsdelivr.net/npm/noto-sans-japanese@1.0.0/fonts/NotoSansJP-Light.otf',
  // src: NotoSansJPLight
});

// Create styles
const styles = StyleSheet.create({
page:{
  fontFamily: "Noto Sans JP",
  paddingBottom: 65,
  paddingTop: 60,
},
  header: {
    padding: 5,
    backgroundColor: 'blue',
  },
  header2: {
    marginTop: '5px',
    padding: 5,
  },
  name: {
    marginTop: '1px',
    padding: '15px',
  },
  headerText: {
    fontSize: '50px',
    textAlign: 'center',
    padding: '5px',
  },
  headerText2: {
    fontSize: '25px',
    textAlign: 'center',
  },
  bodySection: {
    marginTop: '5px',
    padding: '15px',
  },
  bodyText: {
    fontSize: '10px',
    textAlign: 'left',
    marginLeft: '30px',
  },
  bodyTextBold: {
    fontSize: '11px',
    textAlign: 'left',
    marginLeft: '15px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
  },
  bodyTextBold1: {
    fontSize: '11px',
    textAlign: 'left',
    marginLeft: '30px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
  },
  bodyTextUnbold: {
    fontSize: '10px',
    textAlign: 'left',
    marginLeft: '2px',
    display: 'flex',
    flexDirection: 'row',
  },
  bodyText1: {
    marginTop: '1px',
    fontSize: '16px',
    textAlign: 'left',
    marginLeft: '25px',
  },
  bodyText2: {
    marginTop: '10px',
    fontSize: '10px',
    textAlign: 'left',
    marginLeft: '65%',
  },
  noteText: {
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },

  contentText: {
    fontSize: '10px',
    textAlign: 'left',
    marginLeft: '40px',
    padding: '10px',
    border: '1px',
    width: '380px',
    fontWeight: 'bold',
  },
  contentText1: {
    fontSize: '10px',
    textAlign: 'left',
    marginLeft: '30px',
    marginTop: '5px',
    marginBottom: '5px',
    paddingTop: '0px',
    paddingLeft: '10px',
    paddingBottom: '5px',
    paddingRight: '10px',
    border: '1px',
    width: '445px',
    fontWeight: 'bold',
  },
  contentBorder: {
    marginLeft: '40px',
    marginTop: '10px',
    width: '460px',
  },
  conclusionSection: {
    marginTop: '2px',
    padding: '10px',
  },
  noteSection: {
    marginTop: '2px',
    padding: '10px',
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '40%',
    //width: "100px",
    height: '80%',
    marginBottom: '5px',
  },
  imageSize: {
    marginTop: '1px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100px',
    //width: "100px",
    height: '100px',
    marginBottom: '1px',
  },
  signatureBody: {
    textAlign: 'right',
  },
  signature: {
    marginLeft: '70%',
    marginTop: '5px',
    width: '20%',
    //width: "100px",
    height: '50%',
  },
  signatureName: {
    marginLeft: '60%',
  },
  link: {
    textDecoration: 'underline',
    fontSize: '20px',
    textAlign: 'left',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  title: {
    marginTop: '20px',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },

  sectionHeader: {
    marginTop: '20px',
    fontSize: '11px',
    textAlign: 'left',
    marginLeft: '10%',
    width: '80%',
    marginRight: '10%',
  },
  sectionBody: {
    marginTop: '20px',
    fontSize: '10px',
    textAlign: 'left',
    marginLeft: '25%',
    width: '60%',
    marginRight: '10%',
  },
  sectionBody1: {
    marginTop: '2px',
    fontSize: '10px',
    textAlign: 'left',
    marginLeft: '25%',
    width: '60%',
    marginRight: '10%',
  },
  sectionFooter: {
    marginTop: '20px',
    fontSize: '11px',
    textAlign: 'left',
    marginLeft: '10%',
    width: '80%',
    marginRight: '10%',
  },

  tableHeader1: {
    marginTop: '20px',
    fontSize: '14px',
    textAlign: 'left',
    marginLeft: '10%',
    width: '80%',
    marginRight: '10%',
  },
  tableHeader2: {
    marginTop: '20px',
    fontSize: '14px',
    textAlign: 'left',
    marginLeft: '10%',
    width: '80%',
    marginRight: '10%',
  },

  table: {
    marginTop: '20px',
    width: '70%',
    marginLeft: '15%',
    marginRight: '15%',
    fontSize: '10px',
    textAlign: 'center',
  },
  trackTable: {
    marginTop: '20px',
    width: '70%',
    marginLeft: '15%',
    marginRight: '15%',
    fontSize: '10px',
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid black',
  },
  trackRow: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid black',
  },
  header: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  trackHeader: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  bold: {
    fontWeight: 'bold',
  },
  // So Declarative and unDRY 👌
  row1: {
    width: '50%',
    borderRight: '1px solid black',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  trackRow0: {
    width: '65%',
    borderRight: '1px solid black',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  trackRow1: {
    width: '35%',
    borderRight: '1px solid black',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  row2: {
    width: '25%',
    borderRight: '1px solid black',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  row2Add: {
    width: '25%',
    borderRight: '1px solid black',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  trackRow2: {
    width: '30%',
    borderRight: '1px solid black',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  row3: {
    width: '25%',
    borderRight: '1px solid black',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  trackRow3: {
    width: '35%',
    borderRight: '1px solid black',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  row4: {
    width: '25%',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  row5: {
    width: '20%',
  },
});

const baseURL = process.env.REACT_APP_BASE_URL;

// Create Document Component
 const AdecPdfDownload = (props) => {

const [trackData, setTrackData] = useState(props.trackData);
const [deviceData, setDeviceData] = useState(props.deviceData);
const [recordData, setRecordData] = useState(props.recordData);
console.log("track data", trackData );
console.log("device data", deviceData);
const baseURL = "https://trackdev3.ultra-x.jp/Backend/ettms-api-v2.1.0/";


  function getDatePlease(D) {
    return new Date(D).toLocaleString();
  }

  return (
    <Document>
      <Page size="A4" style={styles.page} >
      
        
       
        <br />
       
        <View>
          <Text style={styles.bodyText2}>
            Track ID : {trackData.track_tag.tag_number}
          </Text>
        </View>
        <View>
          <Text style={styles.title}>
            Tracking Information Certificate
          </Text>
        </View>

        <View>
          <Text style={styles.sectionHeader}>
            Regarding the equipment listed below, the data is correct
            that all processing has been completed in accordance with
            the requested processing. It will be certified by the
            Association of Data Erase Certification (ADEC).
          </Text>
        </View>

        <View>
          <Text style={styles.sectionBody}>
            Tracking Start Date :{' '}
            {getDatePlease(trackData.track_start_date)}
          </Text>
        </View>

        <View>
          <Text style={styles.sectionBody1}>
            Tracking Completion Date :{' '}
            {getDatePlease(trackData.track_end_date)}
          </Text>
        </View>

        <View>
        <Text style={styles.sectionBody1}>Required specifications : {trackData.request_type.title_en}</Text>
             

        </View>

        <View>
          <Text style={styles.sectionFooter}>
            This document serves as proof that your company data
            erasure work has been completed.
          </Text>
        </View>

        <View>
          <Text style={styles.tableHeader1}>Device Details</Text>
        </View>

        <View style={styles.table}>
      <View style={[styles.row, styles.header]}>
        <Text style={styles.row1}>Device ID</Text>
        <Text style={styles.row2Add}>Device Type</Text>
        <Text style={styles.row2}>Manufacturer</Text>
        <Text style={styles.row3}>Model</Text>
        <Text style={styles.row4}>Serial</Text>
      </View>
      { deviceData?.map((row) =>
            
        <View key={row._id} style={styles.row} wrap={false}>
          <Text style={styles.row1}>
          {row._id}
          </Text>
          <Text style={styles.row2Add}>{row.device_type.name_en}</Text>
          <Text style={styles.row2}>{row.vendor}</Text>
          <Text style={styles.row3}>{row.model}</Text>
          <Text style={styles.row4}>{row.serial}</Text>
        </View>
        )} 
        
     
    </View>

        <View>
          <Text style={styles.tableHeader2}>
            Record Information Details
          </Text>
        </View>

        <View style={styles.trackTable}>
      <View style={[styles.trackRow, styles.trackHeader]}>
      <Text style={styles.trackRow0}>Device ID</Text>
        <Text style={styles.trackRow1}>Date</Text>
        <Text style={styles.trackRow2}>Processing Name</Text>
        <Text style={styles.trackRow2}>Software Used</Text>
        <Text style={styles.trackRow2}>Location</Text>
        <Text style={styles.trackRow3}>Photo</Text>
      </View>
      
      { recordData?.map((row) =>
        <View key={row._id} style={styles.trackRow} wrap={false}>
          <Text style={styles.trackRow0}>
          {row.device}
          </Text>
          <Text style={styles.trackRow1}>
          {getDatePlease(row.record_date_time)}
          </Text>
          <Text style={styles.trackRow2}>{row.processing_type.title_en}</Text>
          <Text style={styles.trackRow2}>{row.tool_used}</Text>
          <Text style={styles.trackRow2}>{row.location}</Text>
          <Text style={styles.trackRow3}> 
         
          <img src={baseURL+row.image_link} width="200" height="200" alt='devicepic'/>
         
          
          
          </Text>
        </View>
         )} 
     
    </View>
      
        <br />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default AdecPdfDownload;


const StartTrackRegistrationApiHandler = async (id) => {
    const accessToken = localStorage.getItem('access_token');
    console.log(accessToken);
    console.log("record id",id)
    
    const res = await fetch(`${process.env.REACT_APP_BASE_URL+"tracks/trackStartRequest"}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        track_id: id
      }),
      
    });
   
    const data = await res.json();
    console.log(data)
    return data;

}

export default StartTrackRegistrationApiHandler
import {
	Button,
	FormControl,
	Grid,
	TextField,
	CardActionArea,
	CardActions,
	InputAdornment,
	IconButton
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useState } from 'react';
import styles from './UserInfo.module.css';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const baseURL = process.env.REACT_APP_BASE_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserInfo = () => {
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [contact, setContact] = useState(null);
	const [organization, setOrganization] = useState(null);
	const [email, setEmail] = useState(null);
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword(!showConfirmPassword);

	const [passwordSnackbarOpen, setPasswordSnackbarOpen] = useState(false);
	const [wrongPasswordSnackbarOpen, setWrongPasswordSnackbarOpen] =
		useState(false);
	const [confirmPasswordValue, setConfirmPasswordValue] = useState(null);
	const [newPasswordValue, setNewPasswordValue] = useState(null);
	const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
	const handlePasswordDialogOpen = () => {
		setPasswordDialogOpen(true);
	};
	const handlePasswordDialogClose = () => {
		setNewPasswordValue(null);
		setConfirmPasswordValue(null);
		setPasswordDialogOpen(false);
	};
	const handlePasswordDialogConfirm = async (password) => {
		const accessToken = localStorage.getItem('access_token');
		if (!accessToken) {
			navigate('/');
		}

		if (password === null || password === '') {
			setWrongPasswordSnackbarOpen(true);
			setPasswordDialogOpen(false);
		}

		if (password !== null || password !== '') {
			const response = await fetch(`${baseURL}users/changePassword`, {
				method: 'POST',
				body: JSON.stringify({
					password: password
				}),
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			});

			if (response.status === 200) {
				setPasswordSnackbarOpen(true);
				setPasswordDialogOpen(false);
			}
		}
	};
	const handlePasswordSnackbarClose = () => {
		setPasswordSnackbarOpen(false);
		localStorage.removeItem('access_token');
		navigate('/');
	};
	const handleWrongPasswordSnackbarClose = () => {
		setWrongPasswordSnackbarOpen(false);
		localStorage.removeItem('access_token');
		navigate('/');
	};

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [firstNameValue, setFirstNameValue] = useState(null);
	const [lastNameValue, setLastNameValue] = useState(null);
	const [contactNumberValue, setContactNumberValue] = useState(null);
	const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);
	const handleEditProfileDialogOpen = () => {
		setEditProfileDialogOpen(true);
	};
	const handleEditProfileDialogClose = () => {
		setFirstNameValue(firstName);
		setLastNameValue(lastName);
		setContactNumberValue(contact);
		setEditProfileDialogOpen(false);
	};
	const handleEditProfileDialogConfirm = async (fN, lN, cN) => {
		const accessToken = localStorage.getItem('access_token');
		if (!accessToken) {
			navigate('/');
		}

		if (
			fN === null ||
			fN === '' ||
			lN === null ||
			lN === '' ||
			cN === null ||
			cN === ''
		) {
			setEditProfileDialogOpen(false);
			window.location.reload();
		}

		if (
			fN !== null ||
			fN !== '' ||
			lN !== null ||
			lN !== '' ||
			cN !== null ||
			cN !== ''
		) {
			const response = await fetch(`${baseURL}users/updateUser`, {
				method: 'POST',
				body: JSON.stringify({
					first_name: fN,
					last_name: lN,
					contact_number: cN
				}),
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			});

			if (response.status === 200) {
				setSnackbarOpen(true);
				setEditProfileDialogOpen(false);
			}
		}
	};
	const handleSnackbarClose = () => {
		window.location.reload();
		setSnackbarOpen(false);
	};

	useEffect(() => {
		const accessToken = localStorage.getItem('access_token');
		if (!accessToken) {
			navigate('/');
		}
		const FetchData = async () => {
			const response = await fetch(`${baseURL}users/viewUserProfile`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			});
			const _data = await response.json();
			console.log('_data', _data);
			setFirstName(_data?.body?.first_name);
			setLastName(_data?.body?.last_name);
			setContact(_data?.body?.contact_number);
			setOrganization(_data?.body?.organization?.initial);
			setEmail(_data?.body?.email);

			console.log('_fnv', firstNameValue);
			console.log('_lnv', lastNameValue);
			console.log('_cnv', contactNumberValue);
		};
		FetchData();
	}, [
		contact,
		contactNumberValue,
		firstName,
		firstNameValue,
		lastName,
		lastNameValue,
		navigate
	]);

	return (
		<>
			{/* Password */}
			<Snackbar
				open={passwordSnackbarOpen}
				autoHideDuration={5000}
				onClose={handlePasswordSnackbarClose}
			>
				<Alert
					onClose={handlePasswordSnackbarClose}
					severity="success"
					sx={{ width: '100%' }}
				>
					Password Changed Successfully
				</Alert>
			</Snackbar>

			<Snackbar
				open={wrongPasswordSnackbarOpen}
				autoHideDuration={5000}
				onClose={handleWrongPasswordSnackbarClose}
			>
				<Alert
					onClose={handleWrongPasswordSnackbarClose}
					severity="error"
					sx={{ width: '100%' }}
				>
					Password Is Not Valid! Logging Out...
				</Alert>
			</Snackbar>

			<Dialog
				open={passwordDialogOpen}
				onClose={handlePasswordDialogClose}
			>
				<DialogTitle style={{ textAlign: 'center' }}>
					Change Password
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Enter Your Password To Update
					</DialogContentText>
					<Formik>
						{(formik) => {
							return (
								<div>
									<FormControl
										size={`small`}
										variant={`outlined`}
										margin={'normal'}
									>
										<TextField
											fullWidth
											sx={{
												m: 1,
												width: 500,
												maxWidth: '100%'
											}}
											id="password"
											name="password"
											label="New Password"
											size="small"
											type={
												showPassword
													? 'text'
													: 'password'
											}
											value={
												newPasswordValue
													? newPasswordValue
													: ''
											}
											onChange={(event) =>
												setNewPasswordValue(
													event.target.value
												)
											}
											onBlur={formik.handleBlur}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															onClick={
																handleClickShowPassword
															}
														>
															{showPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									</FormControl>
									<FormControl
										size={`small`}
										variant={`outlined`}
										margin={'normal'}
									>
										<TextField
											fullWidth
											sx={{
												m: 1,
												width: 500,
												maxWidth: '100%'
											}}
											id="password"
											name="password"
											label="Confirm New Password"
											size="small"
											type={
												showConfirmPassword
													? 'text'
													: 'password'
											}
											value={
												confirmPasswordValue
													? confirmPasswordValue
													: ''
											}
											onChange={(event) =>
												setConfirmPasswordValue(
													event.target.value
												)
											}
											onBlur={formik.handleBlur}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															onClick={
																handleClickShowConfirmPassword
															}
														>
															{showConfirmPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									</FormControl>
								</div>
							);
						}}
					</Formik>
				</DialogContent>
				<DialogActions style={{ justifyContent: 'center' }}>
					<Button
						variant="contained"
						onClick={handlePasswordDialogClose}
						color="error"
					>
						Cancel
					</Button>
					{(newPasswordValue !== '' || confirmPasswordValue !== '') &&
						newPasswordValue === confirmPasswordValue && (
							<Button
								variant="contained"
								onClick={(event) =>
									handlePasswordDialogConfirm(
										confirmPasswordValue
									)
								}
								color="success"
							>
								Confirm
							</Button>
						)}
				</DialogActions>
			</Dialog>

			{/* Edit profile */}
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={5000}
				onClose={handleSnackbarClose}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity="success"
					sx={{ width: '100%' }}
				>
					Profile Info Updated Successfully
				</Alert>
			</Snackbar>

			<Dialog
				open={editProfileDialogOpen}
				onClose={handleEditProfileDialogClose}
			>
				<DialogTitle style={{ textAlign: 'center' }}>
					Edit User Profile
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Enter Your Details To Update
					</DialogContentText>
					<Formik>
						{(formik) => {
							return (
								<div>
									<FormControl
										size={`small`}
										variant={`outlined`}
										margin={'normal'}
									>
										<TextField
											fullWidth
											sx={{
												m: 1,
												width: 500,
												maxWidth: '100%'
											}}
											id="firstName"
											name="firstName"
											label="First Name"
											size="small"
											type="text"
											defaultValue={firstName}
											onChange={(event) =>
												setFirstNameValue(
													event.target.value
												)
											}
											onBlur={formik.handleBlur}
											error={
												firstNameValue?.trim() === ''
											}
											helperText={
												firstNameValue?.trim() === ''
													? 'First Name is required'
													: ''
											}
										/>
									</FormControl>
									<FormControl
										size={`small`}
										variant={`outlined`}
										margin={'normal'}
									>
										<TextField
											fullWidth
											sx={{
												m: 1,
												width: 500,
												maxWidth: '100%'
											}}
											id="lastName"
											name="lastName"
											label="Last Name"
											size="small"
											type="text"
											defaultValue={lastName}
											onChange={(event) =>
												setLastNameValue(
													event.target.value
												)
											}
											onBlur={formik.handleBlur}
											error={lastNameValue?.trim() === ''}
											helperText={
												lastNameValue?.trim() === ''
													? 'Last Name is required'
													: ''
											}
										/>
									</FormControl>
									<FormControl
										size={`small`}
										variant={`outlined`}
										margin={'normal'}
									>
										<TextField
											fullWidth
											sx={{
												m: 1,
												width: 500,
												maxWidth: '100%'
											}}
											id="contactNumber"
											name="contactNumber"
											label="Contact Number"
											size="small"
											type="text"
											defaultValue={contact}
											onChange={(event) =>
												setContactNumberValue(
													event.target.value
												)
											}
											onBlur={formik.handleBlur}
											error={
												contactNumberValue?.trim() ===
												''
											}
											helperText={
												contactNumberValue?.trim() ===
												''
													? 'Contact is required'
													: ''
											}
										/>
									</FormControl>
								</div>
							);
						}}
					</Formik>
				</DialogContent>
				<DialogActions style={{ justifyContent: 'center' }}>
					<Button
						variant="contained"
						onClick={handleEditProfileDialogClose}
						color="error"
					>
						Cancel
					</Button>
					{(firstNameValue !== null ||
						lastNameValue !== null ||
						contactNumberValue !== null) &&
						firstNameValue?.trim() !== '' &&
						lastNameValue?.trim() !== '' &&
						contactNumberValue?.trim() !== '' && (
							<Button
								variant="contained"
								color="success"
								onClick={(event) =>
									handleEditProfileDialogConfirm(
										firstNameValue
											? firstNameValue
											: firstName,
										lastNameValue
											? lastNameValue
											: lastName,
										contactNumberValue
											? contactNumberValue
											: contact
									)
								}
							>
								Confirm
							</Button>
						)}
				</DialogActions>
			</Dialog>

			<div className={styles['profileForm-wrapper']}>
				<Card sx={{ maxWidth: 450 }}>
					<CardActionArea>
						<Grid container>
							<Grid item xs={6} md={4}>
								<CardMedia
									style={{
										borderRadius: '100%',
										height: 100,
										width: 100,
										margin: 'auto'
									}}
									component="img"
									image="/image/avatar.png"
									alt="avatar"
								/>
							</Grid>
							<Grid item xs={6} md={8}>
								<CardContent style={{ textAlign: 'left' }}>
									<Typography variant="h5">
										{(firstName ? firstName : '') +
											' ' +
											(lastName ? lastName : '')}
									</Typography>

									<Typography>
										{contact ? contact : ''}
									</Typography>

									<Typography>
										{organization ? organization : ''}
									</Typography>

									<Typography>
										{email ? email : ''}
									</Typography>
								</CardContent>
							</Grid>
						</Grid>
					</CardActionArea>
					<CardActions>
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<Button
									variant="outlined"
									size="small"
									color="success"
									onClick={handlePasswordDialogOpen}
								>
									Change Password
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant="outlined"
									size="small"
									color="primary"
									onClick={handleEditProfileDialogOpen}
								>
									Edit Profile
								</Button>
							</Grid>
						</Grid>
					</CardActions>
				</Card>
			</div>
		</>
	);
};

export default UserInfo;


import './App.css';
// import WelcomePanel from './Components/WelcomeComponents/WelcomePanel';
import React from 'react'
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";
import Home from './Components/WelcomeComponents/HomePanel';
import AdminDashboard from './Components/DashboardComponents/AdminDashboard';
import SuperAdminDashboard from './Components/DashboardComponents/SuperAdminDashboard';
import Registration from './Components/WelcomeComponents/RegistrationPanel';
import Login from './Components/WelcomeComponents/LoginPanel';
// import UserDashboard from './Components/DashboardComponents/UserDashboard';
import TrackBoard from './Components/TrackBoardComponents';
// import AdminProfile from './Components/ProfileComponents/AdminProfile'
import RecordTableView from './Components/TrackBoardComponents/TrackBoradTable/DeviceRecordComponents/RecordTableView';
import Handover from './Components/HandoverComponents';
import UserDashboardBasedOnGroup from './Components/DashboardComponents/UserDashboardBasedOnGroup';
import QRCodeReader from './Components/QRCodeComponents/QRCodeReader';
import SingleDeviceInfoShow from './Components/TrackBoardComponents/TrackBoradTable/DeviceComponents/SingleDeviceInfoShow';
import UserProfile from './Components/ProfileComponents/UserProfile';

// import TrackDetailsBeforeHandover from "./Components/HandoverComponents/handover/TrackDetailsBeforeHandover"
// import CollaborationSInglePage from "./Components/HandoverComponents/handover/CollaborationSInglePage"
import CollaborationAfterEmail from './Components/HandoverComponents/Collaboration/CollaborationAfterEmail';

function App() {
 
  return (
    <>
      <Router>
      <Routes>

        <Route path="/" element={<Login/>}/>
        <Route path="/registration/:token"  element={<Registration/>}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/adminDashboard" element={<AdminDashboard/>}/>
        <Route path="/superAdminDashboard" element={<SuperAdminDashboard/>}/>
        <Route path="/userDashboard" element={<UserDashboardBasedOnGroup/>}/>
        <Route path="/trackBoard" element={<TrackBoard/>}/>
        <Route path="/viewTrackRecord" element={<RecordTableView/>}/>
        <Route path="/singleDeviceInfoShow" element={<SingleDeviceInfoShow/>}/>
        <Route path="/handover" element={<Handover/>}/>
        <Route path="/qrCodeReader" element={<QRCodeReader/>}/>
        <Route path="/profile" element={<UserProfile/>}/>
        <Route path="/organization_collaboration/:id" element = { <CollaborationAfterEmail/> } />
        {/* <Route path="/TrackDetails" element = { <TrackDetailsBeforeHandover/> } /> */}
       
      </Routes>
   
    </Router>
   
    </>
  );
}

export default App;

import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../CommonComponents/Layout'
import UserHistory from './UserHistory';
import UserInfo from './UserInfo';
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import styles from "./UserProfile.module.css";

const UserProfile = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('access_token'))
        {
            navigate('/');
        }
    },[navigate])
    return (
        <>
            <div>
                <Layout headerTitle={`PROFILE`}>
                    <div className={styles[`profile-wrapper`]}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4}></Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Box textAlign={`center`}>
                                        <UserInfo />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}></Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box textAlign={`center`}>
                                    <div>
                                        <UserHistory/>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Layout>
            </div>
        </>
    )
}

export default UserProfile
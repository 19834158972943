import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GlobalSearch from './GlobalSearch';
import CreateTrackModal from './CreateTrackModal';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeviceIdModal from './DeviceComponents/DeviceIdModal';
import RecordModal from './DeviceRecordComponents/RecordModal';
import Link from '@mui/material/Link';
import FetchAllDeviceDataByTrackIdApiHandler from './TrackBoardApi/FetchAllDeviceDataByTrackIdApiHandler';
import { useNavigate, useLocation } from 'react-router-dom';
import StartTrackRegistrationApiHandler from './TrackBoardApi/StartTrackRegistrationApiHandler';
import EndTrackRegistrationApiHandler from './TrackBoardApi/EndTrackRegistrationApiHandler';
import FetchAllRecordByDeviceApiHandler from './TrackBoardApi/FetchAllRecordByDeviceApiHandler';
import AdecPdfDownload from './AdecPdfDownload';
import { PDFDownloadLink } from '@react-pdf/renderer';
import FetchSingleTrackDetailsApi from './AdecPdfDownload/AdecPdfApi/FetchSingleTrackDetailsApi';
import FetchFirstGroupDataApi from './TrackBoardApi/FetchFirstGroupDataApi';
import FetchAllTrackBasedOnProjectApi from './TrackBoardApi/FetchAllTrackBasedOnProjectApi';
import styles from './TrackBoardTable.module.css';
import SendHandoverRequestApiHandler from './TrackBoardApi/SendHandoverRequestApi';
import FetchAllAcceptedHandoverApi from './TrackBoardApi/FetchAllAcceptedHandoverApi';
import QRCode from 'qrcode';
import QrCodePdfDownload from '../../DashboardComponents/UserDashboardBasedOnGroup/UserProjectBasedOnGroup/Template/QrCodePdfDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import QRCodeDownload from './QRCodeDownload';

const getDatePlease = (D) => {
  return new Date(D).toLocaleString();
};

const tableColumns = [
  { label: ' ', id: 'TRACE_ID' },
  // { label: '#', id: 'TRACE_ID_SERIAL' },
  { label: 'Tracking ID', id: 'OBJECT_TRACE_ID' },
  { label: 'Registration Date', id: 'REGISTRATION_DATETIME' },
  { label: 'Track Start Date', id: 'track_start_date' },
  { label: 'Completion Date', id: 'COMPLITETION_DATETIME' },
  { label: 'Project Name', id: 'CUSTOMER_PROJECT_ID' },
  {
    label: 'Requirements Specifications',
    id: 'REQUEST_PROCESSING_ID',
  },
  { label: 'ADEC PDF', id: 'ADEC_PDF_URL' },
  { label: 'Worker Name', id: 'SYSTEM_USER_ID' },
  { label: 'Current Tracking Process', id: 'PROCESSING_STATUS_ID' },
  {
    label: 'Tracking Registration',
    id: 'start_tracking_registration',
  },
  {
    label: 'Device Information Registration',
    id: 'device_information_registration',
  },
  { label: 'Handover to Admin', id: 'handover' },
  { label: 'Download QR Code', id: 'qrCode' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableColumns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            style={{ background: '#009688', fontWeight: 'bold' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function createData(
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8,
  h9,
  h10,
  h11,
  h12,
  h13
) {
  return {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    h7,
    h8,
    h9,
    h10,
    h11,
    h12,
    h13,
    history: [
      {
        date: '14321446815038228160_1',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '14321446815038228160_2',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row, trackId } = props;
  const [open, setOpen] = React.useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageOpenForDevice, setMessageOpenForDevice] =
    useState(false);
  const [messageOpenEnd, setMessageOpenEnd] = useState(false);
  const [handoverMessageOpen, setHandoverMessageOpen] =
    useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [clickTrackId, setClickTrackId] = useState();
  const [controlMessage, setControlMessage] = useState(false);
  const [trackEndControlMessage, setTrackEndControlMessage] =
    useState(false);
  const [controlDevieceMessage, setControlDeviceMessage] =
    useState(false);
  const [controlHandoverMessage, setControlHandoverMessage] =
    useState(false);
  const [initialDate, setInitialDate] = useState(
    '1970-01-01T00:00:00.000Z'
  );
  const [trackDataValue, setTrackDataValue] = useState(null);
  const [deviceDataValue, setDeviceDataValue] = useState(null);
  const [recordDataValue, setRecordDataValue] = useState(null);
  const [adecClickId, setAdecClickId] = useState(null);
  const [qrCodeDownload, setQrCodeDownload] = useState([]);
  qrCodeDownload.map((row) => console.log('to data url', row._id));
  const btnDownloadPDFAuto = useRef(null);
  const navigate = useNavigate();
  const [pdfData, setPdfData] = useState(null);
  const [renderePDF_link, setRenderePDF_link] = useState(false);
  const [rendereQR_link, setRendereQR_link] = useState(false);
  const btnQrPDFAuto = useRef(null);
  let isPdfButtonClicked = false;
  const [isLoading, setLoading] = useState(false);

  console.log(initialDate);
  console.log(controlMessage);
  console.log(controlDevieceMessage);

  const MessagehandleClose = () => {
    setMessageOpen(false);
    setMessageOpenEnd(false);
    setMessageOpenForDevice(false);
    setHandoverMessageOpen(false);
  };

  const HandleStartTrackRegistration = async () => {
    const response = await StartTrackRegistrationApiHandler(
      clickTrackId
    );
    console.log('start tarck ', response);
    if (response.success === true) {
      if (response.body === null) {
        console.log('body null');
        console.log(controlDevieceMessage);
        setMessageOpenForDevice(true);
        setControlDeviceMessage(true);
      } else {
        console.log('value');
        setControlMessage(true);
        setControlDeviceMessage(false);
      }
    } else {
      console.log('error');
    }
  };

  const HandleEndTrackRegistration = async () => {
    const response = await EndTrackRegistrationApiHandler(
      clickTrackId
    );
    console.log('End track ', response);
    if (response.success === true) {
      if (response.body.isAnyDeviceHasUnfinishedRecord === true) {
        setTrackEndControlMessage(true);
      } else {
        setControlMessage(true);
      }
    } else {
      console.log('error');
    }
  };

  const HandleHandoverSend = async () => {
    const response = await SendHandoverRequestApiHandler(
      clickTrackId
    );
    console.log('handover response ', response);

    if (response.success === false) {
      console.log('success');
      setControlHandoverMessage(true);
      setControlMessage(false);
    } else {
      setControlHandoverMessage(true);
      setControlMessage(true);
    }
  };

  const ClickTrackIdForMessageStart = (id) => {
    setMessageOpen(true);
    setClickTrackId(id);
  };

  const ClickTrackIdForMessageEnd = (id) => {
    setMessageOpenEnd(true);
    setClickTrackId(id);
  };

  const ClickTrackIdHandover = (id) => {
    setHandoverMessageOpen(true);
    setClickTrackId(id);
  };

  let isButtonClicked = false;
  const handleAutoDownloadPDFCLIK = () => {
    if (!isButtonClicked) {
      console.log('Auto click worked', {
        btnProperty: btnDownloadPDFAuto.current,
      });

      btnDownloadPDFAuto.current.click();
      isButtonClicked = !isButtonClicked;

      setTimeout(() => {
        setRenderePDF_link((prevvalue) => {
          if (prevvalue) {
            isButtonClicked = false;
            return false;
          }
        });
      }, 3000);
    }
  };

  const handleAdecPdf = async (e, id) => {
    console.log('hello', id);
    setAdecClickId(id);
    const trackResult = await FetchSingleTrackDetailsApi(id);
    const deviceResult = await FetchAllDeviceDataByTrackIdApiHandler(
      id
    );
    const recordResult = await FetchAllRecordByDeviceApiHandler(
      deviceResult.body.map((row) => row._id)
    );
    console.log('haaaaaaaaaaaa', recordResult);
    console.log('new track result', trackResult);
    console.log('new device result', deviceResult);
    console.log('new record result', recordResult);

    const _trackData = trackResult.body;
    const _deviceData = deviceResult.body;
    const _recordData = recordResult.body;
    console.log('adec pdf result', {
      adecPDF_resultFtromAPI: trackResult,
      _trackData,
    });
    setTrackDataValue(_trackData); // useState call
    setDeviceDataValue(_deviceData);
    setRecordDataValue(_recordData);

    setRenderePDF_link((prevvalue) => {
      setTimeout(() => {
        handleAutoDownloadPDFCLIK();
      }, 3000);
      return true;
    });
  };

  const GetDeviceDataByTrackId = async (trackId) => {
    const allData = await FetchAllDeviceDataByTrackIdApiHandler(
      trackId
    );
    //console.log(allData.body);
    if (allData.success === true) {
      setDeviceData(await allData.body);
      setOpen(!open);
      //console.log("data aise ",allData.body)
    }
  };
  //console.log("it's device", deviceData)
  const options = {
    type: 'png',
    rendererOpts: {
      quality: 0.92,
    },
    scale: 5,
  };

  const DownloadQRCodeHandler = async (id) => {
    const allData = await FetchAllDeviceDataByTrackIdApiHandler(id);
    if (allData.success === true) {
      setQrCodeDownload(await allData.body);
      console.log('qr code data ', allData.body[0].device_tag);
      QRCode.toDataURL(qrCodeDownload[0]._id, options, (err, url) => {
        if (err) console.log(err);
        else {
          console.log('jamal san url', url);
        }
      });
    }

    //console.log("new new",allData.body);
  };

  const handleQrCodePdf = async (trackId) => {
    console.log('__handleQrCodePdf starts');
    // setProjectName(projectName);
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL +
      'devices/showAllActiveDevicesOfFollowingTrack'
      }`,
      {
        method: 'POST',
        body: JSON.stringify({
          track_id: trackId,
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response) {
      return;
    }
    const _pdfData = await response.json();
    console.log('__handleQrCodePdf data', _pdfData);
    setPdfData(_pdfData?.body);
    setRendereQR_link((prevvalue) => {
      setLoading(true);
      setTimeout(() => {
        if (!isPdfButtonClicked) {
          btnQrPDFAuto.current.click();
          isPdfButtonClicked = !isPdfButtonClicked;
          setTimeout(() => {
            setRendereQR_link((prevvalue) => {
              if (prevvalue) {
                isPdfButtonClicked = false;
                return false;
              }
            });
          }, 3000);
        }
      }, 3000);
      setLoading(false);
      return true;
    });
    console.log('__handleQrCodePdf ends');
  };

  return (
    <React.Fragment>
      {controlMessage === false &&
        trackEndControlMessage === false ? (
        <Dialog
          open={messageOpenEnd}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are Ending Track Id !!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Disagree</Button>
            {messageOpenEnd === true ? (
              <Button onClick={HandleEndTrackRegistration}>
                Agree
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : controlMessage === false &&
        trackEndControlMessage === true ? (
        <Dialog
          open={messageOpenEnd}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">{'Oppps'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have unfinished track record !!!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={messageOpenEnd}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {'Congratulations !!!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are successfully Ended Track Id
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {controlMessage === false && controlDevieceMessage === false ? (
        <Dialog
          open={messageOpen}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are Starting Track Id !!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Disagree</Button>
            {messageOpen === true ? (
              <Button onClick={HandleStartTrackRegistration}>
                Agree
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : controlMessage === false &&
        controlDevieceMessage === true ? (
        <Dialog
          open={messageOpen}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {'Opps !!!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You need to device Registration first !!!!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={messageOpen}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {'Congratulations !!!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are successfully start Track Id
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {controlMessage === false &&
        controlHandoverMessage === false ? (
        <Dialog
          open={handoverMessageOpen}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you send Handover request to Admin !!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Disagree</Button>
            {handoverMessageOpen === true ? (
              <Button onClick={HandleHandoverSend}>Agree</Button>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : controlMessage === false &&
        controlHandoverMessage === true ? (
        <Dialog
          open={handoverMessageOpen}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {'Opps !!!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The track has been started or no device added or already
              handover !!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={handoverMessageOpen}
          onClose={MessagehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {'Congratulations !!!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are successfully done handover
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={MessagehandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          {row.handover === false ? (
            <Tooltip title="Device Information">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => GetDeviceDataByTrackId(row._id)}
              >
                {open ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Tooltip>
          ) : trackId === true ? (
            <Tooltip title="Device Information">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => GetDeviceDataByTrackId(row._id)}
              >
                {open ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Device Information">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => GetDeviceDataByTrackId(row._id)}
                disabled
              >
                {open ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
        </TableCell>

        <TableCell component="th" scope="row" align="center">
          {row.track_tag === null ? 'null' : row.track_tag.tag_number}
        </TableCell>
        <TableCell align="center">
          {getDatePlease(row.track_registration_date)}
        </TableCell>
        <TableCell align="center">
          {row.track_start_date === initialDate
            ? 'Not Started'
            : getDatePlease(row.track_start_date)}
        </TableCell>
        <TableCell align="center">
          {row.track_end_date === initialDate
            ? 'Not Completed'
            : getDatePlease(row.track_end_date)}
        </TableCell>
        <TableCell align="center">{row.project.name_en}</TableCell>
        <TableCell align="center">
          {row.request_type.title_en}
        </TableCell>
        <TableCell align="center">
          {row.track_end_date === initialDate ? (
            <p>Not Started</p>
          ) : (
            <Button
              style={{
                color: 'white',
                backgroundColor: 'green',
                fontSize: '12px',
                padding: '10px',
                border: '2px',
                borderRadius: '5px',
                width: '100px',
                wordWrap: 'break-word',
              }}
              onClick={(e) => {
                handleAdecPdf(e, row._id);
              }}
            >
              Download
            </Button>
          )}
          {renderePDF_link && (
            <PDFDownloadLink
              document={
                <AdecPdfDownload
                  trackData={trackDataValue}
                  deviceData={deviceDataValue}
                  recordData={recordDataValue}
                />
              }
              fileName={`${adecClickId}_${Date.now()}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  ''
                ) : (
                  <Button
                    ref={btnDownloadPDFAuto}
                    onClick={handleAutoDownloadPDFCLIK}
                  ></Button>
                )
              }
            </PDFDownloadLink>
          )}
        </TableCell>

        <TableCell align="center">
          {row.created_by_user.first_name +
            ' ' +
            row.created_by_user.last_name}
        </TableCell>
        <TableCell align="center">{row.status}</TableCell>
        <TableCell align="center">
          {row.handover === false ? (
            row.track_start_date === initialDate ? (
              <Button
                style={{
                  color: 'white',
                  backgroundColor: 'rgb(91, 4, 4)',
                  fontSize: '12px',
                  padding: '10px',
                  border: '2px',
                  borderRadius: '5px',
                  width: '250px',
                  wordWrap: 'break-word',
                }}
                onClick={(e) => {
                  ClickTrackIdForMessageStart(row._id);
                }}
              >
                Start Tracking Registration
              </Button>
            ) : (
              <Button
                style={{
                  color: 'white',
                  backgroundColor: 'rgb(91, 4, 4)',
                  fontSize: '12px',
                  padding: '10px',
                  border: '2px',
                  borderRadius: '5px',
                  width: '250px',
                  wordWrap: 'break-word',
                }}
                onClick={(e) => {
                  ClickTrackIdForMessageEnd(row._id);
                }}
              >
                End Tracking Registration
              </Button>
            )
          ) : (
            <Button
              style={{
                color: 'white',
                backgroundColor: 'gray',
                fontSize: '12px',
                padding: '10px',
                border: '2px',
                borderRadius: '5px',
                width: '250px',
                wordWrap: 'break-word',
              }}
            >
              Handover
            </Button>
          )}
        </TableCell>

        <TableCell align="center">
          {row.handover === false ? (
            <DeviceIdModal id={row._id} />
          ) : (
            <h5>Handover to another company</h5>
          )}
        </TableCell>
        <TableCell align="center">
          {row.handover === false ? (
            <Button
              style={{
                color: 'white',
                backgroundColor: 'blue',
                fontSize: '12px',
                padding: '10px',
                border: '2px',
                borderRadius: '5px',
                width: '150px',
                wordWrap: 'break-word',
              }}
              onClick={(e) => {
                ClickTrackIdHandover(row._id);
              }}
            >
              Handover
            </Button>
          ) : (
            <h5>Handover done</h5>
          )}
        </TableCell>

        {/* <TableCell align="center">
                      <Button

style={{
       color: 'white',
       backgroundColor: 'red',
       fontSize: '12px',
       padding: '10px',
       border: '2px',
       borderRadius: '5px',
       width: '150px',
       wordWrap: 'break-word'

      }}

      onClick={(e) => {
        DownloadQRCodeHandler(row._id)
      }}

     >
       Download 
  </Button>
                      </TableCell> */}

        {/* <TableCell align="center">
                <Button
                variant="contained"
                value={row._id}
                onClick={() => {DownloadQRCodeHandler(row._id)}}>
                  <PictureAsPdfIcon />
                </Button>
                {
                  renderePDF_link && 
                    <PDFDownloadLink
                    document={<QrCodePdfDownload qrCodeDownload={qrCodeDownload} projectName={`test`}/>}
                    fileName={`${'test'}_QR_LIST_${Date.now()}.pdf`}
                    >
                      {
                        <Button>test</Button>
                      }
                    </PDFDownloadLink>
                }
              </TableCell> */}

        <TableCell align="center">
          <Button
            variant="contained"
            value={row._id}
            disabled={row.deviceCount === 0 ? true : false}
            onClick={() => {
              handleQrCodePdf(row._id);
            }}
          >
            <PictureAsPdfIcon />
          </Button>
          {rendereQR_link && (
            <PDFDownloadLink
              document={
                <QRCodeDownload
                  pdfData={pdfData}
                  projectName={`${row.project.name_en}`}
                />
              }
              fileName={`${row.project.name_en
                }_QR_TAGS_${Date.now()}.pdf`}
            >
              {<Button ref={btnQrPDFAuto}></Button>}
            </PDFDownloadLink>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={15}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Device Information
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow
                    align="center"
                    style={{ background: '#009688' }}
                  >
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Device ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Device Name
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Vendor
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Model
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Serial
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Specification
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      State
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Comment
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Track Record Registration
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      View Track Record
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: 'bold' }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deviceData.map((deviceRow) => (
                    <TableRow key={deviceRow._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {deviceRow.device_tag}
                      </TableCell>
                      <TableCell align="center">
                        {deviceRow.device_type.name_en}
                      </TableCell>
                      <TableCell align="center">
                        {deviceRow.vendor}
                      </TableCell>
                      <TableCell align="center">
                        {deviceRow.model}
                      </TableCell>
                      <TableCell align="center">
                        {deviceRow.serial}
                      </TableCell>
                      <TableCell align="center">
                        {deviceRow.spacifications === ''
                          ? 'null'
                          : deviceRow.spacifications}
                      </TableCell>
                      <TableCell align="center">
                        {deviceRow.state === ''
                          ? 'null'
                          : deviceRow.state}
                      </TableCell>
                      <TableCell align="center">
                        {deviceRow.comment === ''
                          ? 'null'
                          : deviceRow.comment}
                      </TableCell>
                      <TableCell align="center">
                        {deviceRow.recordReported === false && deviceRow.request_type.processing_types.length !== deviceRow.recordCreated ? (
                          <RecordModal id={deviceRow._id} />
                        ) : deviceRow.request_type.processing_types.length === deviceRow.recordCreated ?
                          <Button
                            disabled
                            style={{
                              color: 'white',
                              backgroundColor: 'gray',
                              fontSize: '12px',
                              padding: '10px',
                              border: '2px',
                              borderRadius: '5px',
                              width: '250px',
                              wordWrap: 'break-word',
                            }}
                          >
                            Recorc Request completed
                          </Button>
                          :
                          (
                            <Button
                              disabled
                              style={{
                                color: 'white',
                                backgroundColor: 'gray',
                                fontSize: '12px',
                                padding: '10px',
                                border: '2px',
                                borderRadius: '5px',
                                width: '250px',
                                wordWrap: 'break-word',
                              }}
                            >
                              Device Record Reported
                            </Button>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          style={{
                            color: 'white',
                            backgroundColor: 'blue',
                            fontSize: '12px',
                            padding: '10px',
                            border: '2px',
                            borderRadius: '5px',
                            width: '250px',
                            wordWrap: 'break-word',
                          }}
                          onClick={() =>
                            navigate('/viewTrackRecord', {
                              state: { id: deviceRow._id },
                            })
                          }
                        >
                          View Track Record
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Stack
                          justifyContent="center"
                          spacing={2}
                          direction="row"
                        >
                          <Button variant="contained">Update</Button>
                          <Button variant="outlined" color="error">
                            Disable
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export default function TrackBoardTable() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [trackBoardData, setTrackBoardData] = useState([]);
  const [firstGroupData, setFirstGroupData] = useState([]);
  const [firstGroupId, setFirstGroupId] = useState(null);
  const [searchItem, setSearchItem] = useState('');
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const { trackId } = state;

  console.log('track data', trackBoardData);
  console.log('first group data', firstGroupData);
  console.log('first group id', firstGroupId);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const FetchFirstGroupData = async () => {
      const allData = await FetchFirstGroupDataApi();
      console.log('this is new group work', allData.body);
      if (allData.success === true) {
        setFirstGroupData(await allData.body[0]._id);
        setFirstGroupId(await allData.body[0]._id);
        console.log('data aise ');
      }
    };
    FetchFirstGroupData();
  }, []);

  useEffect(() => {
    const FetchTrackData = async () => {
      setLoading(true);
      const allData =
        trackId === true
          ? await FetchAllAcceptedHandoverApi()
          : await FetchAllTrackBasedOnProjectApi(trackId);
      console.log(allData.body);
      if (allData.success === true) {
        setTrackBoardData(await allData.body);
        console.log('track data aise ');
        setLoading(false);
      }
    };
    FetchTrackData();
  }, [trackId]);

  return (
    <>
      <div
        role="presentation"
        className={styles['layoutForBreadcrumbs']}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="hover"
            color="inherit"
            href="/userDashboard"
          >
            User Dashboard
          </Link>
          <Typography color="text.primary">Track Board</Typography>
        </Breadcrumbs>
      </div>
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          align: 'right',
          alignItems: 'center',
          width: '100%',
        }}
        elevation={5}
      >
        <GlobalSearch
          searchItem={searchItem}
          setSearchItem={setSearchItem}
        />
        {trackId === true ? '' : <CreateTrackModal />}
      </Paper>
      {loading === true ? (
        <h3 style={{ textAlign: 'center' }}>Loading...</h3>
      ) : trackBoardData && trackBoardData.length === 0 ? (
        <h3 style={{ textAlign: 'center' }}>No record Available</h3>
      ) : (
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: '62vh',
                maxWidth: '100%',
                width: '100%',
              }}
            >
              <Table
                aria-label="collapsible table"
                stickyHeader
                size={dense ? 'small' : 'medium'}
                sx={{ minWidth: 750 }}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />

                <TableBody>
                  {stableSort(
                    trackBoardData,
                    getComparator(order, orderBy)
                  )
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .filter((val) => {
                      if (searchItem === '') {
                        return trackBoardData;
                      } else if (
                        val.track_tag.tag_number
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase()) ||
                        getDatePlease(val.track_registration_date)
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase()) ||
                        getDatePlease(val.track_start_date)
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase()) ||
                        getDatePlease(val.track_end_date)
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase()) ||
                        val.project.name_en
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase()) ||
                        val.request_type.title_en
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase()) ||
                        val.created_by_user.first_name
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase()) ||
                        val.created_by_user.last_name
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase()) ||
                        val.status
                          .toString()
                          .toLowerCase()
                          .includes(searchItem.toLowerCase())
                      ) {
                        return trackBoardData;
                      }
                    })
                    .map((row) => (
                      <Row
                        key={row._id}
                        row={row}
                        trackId={trackId}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={trackBoardData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}


import React, { useState }from "react";
//  import Image from 'next/image';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";
Font.register({
  family: "Noto Sans JP",
  src: "https://cdn.jsdelivr.net/npm/noto-sans-japanese@1.0.0/fonts/NotoSansJP-Light.otf"
});

const localDateConvert = (D) =>{
    return new Date(D).toLocaleString();
}

// Create styles
const styles = StyleSheet.create({
    page:{
        fontFamily: "Noto Sans JP",
        paddingBottom: '65px',
        paddingTop: '60px',
        paddingHorizontal: '35px'
    },
    gridContainer: {
      fontSize: "9px",
      margin: "8px",
      width: "100%",
      height: "auto",
      border: "1px solid black",
    },
    tableHeader: {
        fontWeight: "bold",
        textAlign: "center",
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid black'
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        borderRight: '1px solid black'
    },
    tableBody: {
        textAlign: "center",
        display: 'flex',
        flexDirection: 'row'
    },
    col: {
      overflow: '',
      lineHeight: 1.5,
      width: '100%',
      borderRight: '1px solid black'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

// Create Document Component
const SummaryPdfDownload = (props) => {
const [pdfData, setPdfData] = useState(props.summaryPdfData);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <br />
        <View>
          <Text style={styles.projectName}>案件番号：
          {props.projectName}
          </Text>
        </View>

        <View>
            <Text style={styles.projectTitle}>
            プロジェクトまとめ
            </Text>
        </View>

        <View>
            <Text style={styles.tableHeader}>
            追跡IDごと情報リスト
            </Text>
        </View>

        <View style={styles.gridContainer}>
            <View style={styles.tableHeader} wrap={true}>
                <Text style={styles.row}>追跡ID</Text>
                <Text style={styles.row}>機器種別</Text>
                <Text style={styles.row}>登録日付</Text>
                <Text style={styles.row}>完了日付</Text>
                <Text style={styles.row}>要求仕様</Text>
                <Text style={styles.row}>現在追跡処理</Text>
                <Text style={styles.row}>機器ID</Text>
                <Text style={styles.row}>ベンダー</Text>
                <Text style={styles.row}>プロダクト</Text>
                <Text style={styles.row}>シリアル</Text>
            </View>
        {pdfData?.map((row) =>
            <View key={row._id} style={styles.tableBody}>
                {/* <Text style={styles.col}>
                {row.device_tag.substring(0, row.device_tag.indexOf("."))}
                </Text> */}
                <Text style={styles.col}>
                {row.device_tag
                .substring(0, row.device_tag.indexOf("."))
                .match(new RegExp(`.{1,10}`, "g"))
                .map((chunk, index) => (
                    <Text key={index}>{chunk}</Text>
                ))
                }
                </Text>
                <Text style={styles.col}>{row.device_type.name_en}</Text>
                <Text style={styles.col}>{localDateConvert(row.createdAt)}</Text>
                <Text style={styles.col}>{localDateConvert(row.updatedAt)}</Text>
                <Text style={styles.col}>{row.spacifications}</Text>
                <Text style={styles.col}>{row.state}</Text>
                <Text style={styles.col}>
                {row.device_tag.substring(row.device_tag.indexOf(".") + 1)}
                </Text>
                <Text style={styles.col}>{row.vendor}</Text>
                <Text style={styles.col}>{row.model}</Text>
                <Text style={styles.col}>{row.serial}</Text>
            </View>
            )}
        </View>
        <br />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
      </Page>
    </Document>
  );
};

export default SummaryPdfDownload;
import React ,{useEffect}from 'react'
import styles from "./QRCodeReader.module.css"
import { useNavigate } from 'react-router-dom'
import Layout from '../../CommonComponents/Layout';
import QRCodeScanner from './QRCodeScanner';

const QRCodeReader = () => {

  const navigate = useNavigate();
  useEffect(()=>{

    if(!localStorage.getItem('access_token'))
    {
        navigate('/')
    }
  },[navigate])
  return (
    <>
    <Layout>
    <div className={styles[`adminDashboard-wrapper`]}>
      <div>     
      </div>
    <div className={styles["adminDashboardTable-wraper"]}>
     <QRCodeScanner/>
    </div>
    </div>
    </Layout>

    </>
  )
}

export default QRCodeReader
import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { useState} from "react";
import SyncLoader from "react-spinners/SyncLoader";
import styles from "./LoginForm.module.css";
import { useNavigate } from "react-router-dom";
import LoginApi from "../LoginApiHandler/LoginApi";

const LoginForm = () => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const HandleLoginRedirect = () => {
    navigate('/home')
  }

  const initialValues = {
    email: "",
    password: "",
  };

  const validate = (values) => {
    setIsValid(true);
    let errors = {};
    if (!values.email) {
      errors.email = "Email is required";
      setIsValid(false);
    }
    else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Email address mismatch";
      setIsValid(false);
    }

    if (!values.password) {
      errors.password = "Password is required";
      setIsValid(false);
    }

    return errors;
  };

  const onSubmit = async (values) => {
   
    localStorage.removeItem('access_token');
    const res = await LoginApi(values);
    console.log("login res", res);
    console.log("Say Hello!")
    console.log(res.success)

    if (res.success === true) {
      localStorage.setItem("access_token", res.access_token)
      localStorage.setItem('permissions', JSON.stringify(res.body.permission.permissions))
      // console.log(localStorage.getItem('access_token'));
      // console.log(JSON.parse(localStorage.getItem('permissions')))
      setIsLoginSuccess(true);
      setIsError(false);
      setTimeout(HandleLoginRedirect, 1000)
      navigate("/home")
    }
    else {
      setIsLoginSuccess(false);
      setIsError(true);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => {
          return (
            <Form>
              <div className={styles["login-wrapper"]}>
                <h2 className={styles["login-header"]}>
                  Login
                </h2>
                <Divider />
                {isLoginSuccess && (
                  <div className={styles["login-success-message"]}>
                    Congratulation successfully login
                  </div>
                )}
                {isError && (
                  <div className={styles["login-error-message"]}>
                    Email or Password is Incorrect
                  </div>
                )}
                <hr />
                <br />
                <Box
                  sx={{
                    "& > :not(style)": {
                      m: 0,
                      width: "100%",
                      marginBottom: "1rem",
                    },
                  }}
                >
                  <div>
                    <FormControl className={styles["login-form"]} fullWidth>
                      <TextField
                        id="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={
                          formik.touched.email &&
                          Boolean(formik.errors.email)
                        }
                      />
                      <span className={styles["error"]}>
                        {formik.touched.email && formik.errors.email ? (
                          <div>{formik.errors.email}</div>
                        ) : null}
                      </span>
                    </FormControl>
                  </div>

                  <div className={styles["login-input-field"]}>
                    <FormControl className={styles["login-form"]} fullWidth>
                      <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                      />
                      <span className={styles["error"]}>
                        {formik.touched.password && formik.errors.password ? (
                          <div>{formik.errors.password}</div>
                        ) : null}
                      </span>
                    </FormControl>
                  </div>
                </Box>
                <Stack>

                  {/* <ForgetPassword /> */}
                </Stack>
                <Stack>

                  <Button
                    type="submit"
                    disabled={!isValid && !isLoading}
                    variant="contained"
                    id={styles["login-in-btn"]}
                  >
                    {isLoading ? (
                      <SyncLoader />
                    ) : (
                      'Sign In'
                    )}
                  </Button>
                </Stack>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  )
}

export default LoginForm;
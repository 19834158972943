const CreateRecordApiHandler = async (values, processingId, id, image) => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	console.log('processsing type check', processingId);

	var form = new FormData();
	form.append('processing_type', processingId);
	form.append('device_id', id);
	form.append('image_upload', image);
	form.append('location', values.location);
	form.append('tool_used', values.toolUsed);
	form.append('comment', values.comment);
	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'records/createRecord'}`,
		{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: form
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default CreateRecordApiHandler;


const LoginApi = async (values) => {
    // https://tracktest.ultra-x.jp/backend/users/login
    console.log("aita e env", process.env.REACT_APP_BASE_URL)

    const res = await fetch(`${process.env.REACT_APP_BASE_URL+"users/login"}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: values.email,
        password: values.password,
      }),
    });
    const data = await res.json();
    return data;
  

}

export default LoginApi
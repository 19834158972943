import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import styles from './UserTab.module.css';
import ShowAllActiveGroupApiHandler from "../UserDashboardApiHandler/ShowAllActiveGroupApi";
import { useEffect} from "react";
import ProjectModal from "../../../ProjectComponents/ProjectModal";
import UserProjectBasedOnGroup from "../UserProjectBasedOnGroup";
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
   
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  

const UserTab = () => {
    const [value, setValue] = useState(0);
    const [groupData, setGroupData] = useState([]);
    const [clickGroupId, setClickGroupId] = useState(0);
    const navigate = useNavigate();

    console.log(groupData);
    console.log('click value', clickGroupId)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  

  useEffect( () =>{
    const FetchGroupData = async() =>{
      const allData = await ShowAllActiveGroupApiHandler(); 
      console.log(allData.body);
       if(allData.success === true)
       {
        setGroupData(await allData.body);
        setClickGroupId(await allData.body[value]._id)
        console.log("data aise koitte ")
           
       }
          
    }
    FetchGroupData();
  },[value])

  return (
    <>
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="group-tabs"
         
        >
            {groupData.map( row => <Tab label={`${row.name_en}`} {...a11yProps(value)} />)}
            
            <Box flexGrow={1} />
           
            <div className={styles["handover-button-control"]}>
                <Button variant="contained" onClick={
                                () => navigate("/trackBoard", { state: { trackId : true } })
                               }>Accepted Handover</Button>
            </div>
            <ProjectModal/>
        </Tabs>
      </Box>

      <TabPanel value={value} index={value}>
       
        <UserProjectBasedOnGroup clickGroupId={clickGroupId}/>
      </TabPanel>
      
    </Box>
    
    </>
  )
}

export default UserTab;
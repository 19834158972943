import React, { useEffect } from 'react'

import styles from "./HandoverComponents.module.css"
import { useNavigate } from 'react-router-dom'
import Layout from '../CommonComponents/Layout';
import HandoverTabs from './HandoverTabs';
// import TrackBoardTable from './TrackBoradTable';


const Handover = () => {
  const navigate = useNavigate();
  useEffect(() => {

    if (!localStorage.getItem('access_token')) {
      navigate('/')
    }
  }, [navigate])
  return (
    <>
      <Layout>
        <div className={styles[`adminDashboard-wrapper`]}>
          <div>

            {/* <TrackBoardTable/> */}
          </div>
          <div className={styles["adminDashboardTable-wraper"]}>
            <HandoverTabs />
          </div>
        </div>
      </Layout>

    </>
  )
}

export default Handover
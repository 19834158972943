import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import styles from './UserHistory.module.css';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const baseURL = process.env.REACT_APP_BASE_URL;

const localDateConvert = (D) => {
	return new Date(D).toLocaleString();
};

const UserHistory = () => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [tableData, setTableData] = useState(null);
	const [historyData, setHistoryData] = useState({
		processing_type: {
			title_en: ''
		},
		recorded_by_user: {
			first_name: '',
			last_name: ''
		},
		record_date_time: '',
		location: '',
		tool_used: '',
		reported: '',
		comment: ''
	});

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const navigate = useNavigate();

	const [detailDialogOpen, setDetailDialogOpen] = useState(false);
	const handleDetailDialogOpen = async (id) => {
		await GetHistoryDataById(id);
		setDetailDialogOpen(true);
	};
	const handleDetailDialogClose = () => {
		setDetailDialogOpen(false);
	};

	const GetHistoryDataById = async (id) => {
		const accessToken = localStorage.getItem('access_token');
		const FetchData = async () => {
			const response = await fetch(`${baseURL}records/showRecordById`, {
				method: 'POST',
				body: JSON.stringify({
					record_id: id
				}),
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			});
			const _hData = await response.json();
			setHistoryData(_hData.body);
		};
		FetchData();
	};

	useEffect(() => {
		const accessToken = localStorage.getItem('access_token');
		if (!accessToken) {
			navigate('/');
		}
		const FetchData = async () => {
			const response = await fetch(
				`${baseURL}workHistory/showAllActiveWorkHistory`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			const _tData = await response.json();
			console.log('_tData', _tData);
			setTableData(_tData?.body);
		};
		FetchData();
	}, [navigate]);

	return (
		<Paper
			sx={{ width: '95%', overflow: 'hidden' }}
			className={styles['userHistory-wrapper']}
		>
			<div>
				<h3>Work History</h3>
			</div>

			<TableContainer sx={{ maxHeight: 450 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell
								style={{
									backgroundColor: ' rgb(203, 200, 200)',
									fontWeight: 'bold'
								}}
								align="center"
							>
								User Name
							</TableCell>
							<TableCell
								style={{
									backgroundColor: ' rgb(203, 200, 200)',
									fontWeight: 'bold'
								}}
								align="center"
							>
								Activity
							</TableCell>
							<TableCell
								style={{
									backgroundColor: ' rgb(203, 200, 200)',
									fontWeight: 'bold'
								}}
								align="center"
							>
								Time
							</TableCell>
							<TableCell
								style={{
									backgroundColor: ' rgb(203, 200, 200)',
									fontWeight: 'bold'
								}}
								align="center"
							>
								Details
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableData?.map((row) => (
							<TableRow
								key={row._id}
								sx={{ '& > *': { borderBottom: 'unset' } }}
							>
								<Dialog
									maxWidth={false}
									open={detailDialogOpen}
									onClose={handleDetailDialogClose}
									BackdropProps={{
										style: {
											backgroundColor: 'transparent'
										}
									}}
									sx={{
										'& .MuiPaper-root': {
											padding: '12px',
											boxShadow:
												'0 2px 2px rgba(0,0,0,0.05)'
										}
									}}
								>
									<DialogTitle
										style={{ textAlign: 'center' }}
									>
										History Details
									</DialogTitle>
									<TableContainer sx={{ overflowX: 'auto' }}>
										<Table
											stickyHeader
											aria-label="sticky table"
										>
											<TableHead>
												<TableRow>
													<TableCell
														align="center"
														style={{
															background:
																'#009688',
															fontWeight: 'bold'
														}}
													>
														User Name
													</TableCell>
													<TableCell
														align="center"
														style={{
															background:
																'#009688',
															fontWeight: 'bold'
														}}
													>
														Processing Type
													</TableCell>
													<TableCell
														align="center"
														style={{
															background:
																'#009688',
															fontWeight: 'bold'
														}}
													>
														Record Time
													</TableCell>
													<TableCell
														align="center"
														style={{
															background:
																'#009688',
															fontWeight: 'bold'
														}}
													>
														Location
													</TableCell>
													<TableCell
														align="center"
														style={{
															background:
																'#009688',
															fontWeight: 'bold'
														}}
													>
														Used Tool
													</TableCell>
													<TableCell
														align="center"
														style={{
															background:
																'#009688',
															fontWeight: 'bold'
														}}
													>
														Reported
													</TableCell>
													<TableCell
														align="center"
														style={{
															background:
																'#009688',
															fontWeight: 'bold'
														}}
													>
														Comment
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell align="center">
														{historyData
															.recorded_by_user
															.first_name +
															' ' +
															historyData
																.recorded_by_user
																.last_name}
													</TableCell>
													<TableCell align="center">
														{historyData
															.processing_type
															.title_en === ''
															? ''
															: historyData
																	.processing_type
																	.title_en}
													</TableCell>
													<TableCell align="center">
														{localDateConvert(
															historyData.record_date_time
														)}
													</TableCell>
													<TableCell align="center">
														{historyData.location ===
														''
															? ''
															: historyData.location}
													</TableCell>
													<TableCell align="center">
														{historyData.tool_used ===
														''
															? ''
															: historyData.tool_used}
													</TableCell>
													<TableCell align="center">
														{historyData.reported ===
														''
															? ''
															: historyData.reported
																	.toString()
																	.toUpperCase()}
													</TableCell>
													<TableCell align="center">
														{historyData.comment ===
														''
															? ''
															: historyData.comment}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
									<DialogActions>
										<Button
											autoFocus
											variant="contained"
											color="error"
											onClick={handleDetailDialogClose}
										>
											Close
										</Button>
									</DialogActions>
								</Dialog>
								<TableCell
									style={{ display: 'none' }}
									align="center"
								>
									{row.history_operation_id}
								</TableCell>
								<TableCell align="center">
									{row.history_user.first_name +
										' ' +
										row.history_user.last_name}
								</TableCell>
								<TableCell align="center">
									{row.history_type}
								</TableCell>
								<TableCell align="center">
									{localDateConvert(row.history_date_time)}
								</TableCell>
								<TableCell align="center">
									<Button
										size="small"
										variant="contained"
										color="primary"
										onClick={async () =>
											handleDetailDialogOpen(
												row.history_operation_id
											)
										}
									>
										View Details
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={tableData ? tableData.length : 5}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};

export default UserHistory;

import {React} from 'react';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import styles from './GlobalSearch.module.css';


export default function GlobalSearch(props) {

  return (
    <>
   
      <Tooltip title="Search">
      <IconButton type="button" color="primary" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      </Tooltip>
      <InputBase
        type='text'
        sx={{ ml: 1, flex: 1 ,'&:hover': {
          borderBottom: '2px solid black',
         
        }}}
        placeholder="Search in Page"
        inputProps={{ 'aria-label': 'search' }}
        value = {props.SearchItem}
        
        
        onChange={(e)=>{props.setSearchItem(e.target.value)}}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
     
    </>
  );
}
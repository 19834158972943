
import { Button, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";

import { useState } from "react";
import styles from "./CreateDeviceType.module.css";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CreateDeviceTypeApiHandler from "../DeviceApiHandler/CreateDeviceTypeApiHandler";

const CreateDeviceType = ({setNextButton}) => {
  const [isValid, setIsValid] = useState(false);
  const [isProjectCreate, setIsProjectCreate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [status,setStatus] = useState(false);


  const initialValues = {
    deviceNameEn: "",
    deviceNameJp: "",
    deviceDetailsEn: "",
    deviceDetailsJp: ""
  };

  const validate = (values) => {
    setIsValid(true);
    let errors = {};
    if (!values.deviceNameEn) {
      errors.deviceNameEn = "Device name is required";
      setIsValid(false);
    }

    if (!values.deviceNameJp) {
      errors.deviceNameJp = "Device name is required";
      setIsValid(false);
    }
    if (!values.deviceDetailsEn) {
      errors.deviceDetailsEn = "Device details is required";
      setIsValid(false);
    }
    if (!values.deviceDetailsJp) {
      errors.deviceDetailsJp = "Device details is required";
      setIsValid(false);
    }


    return errors;
  };


 
  const onSubmit = async (values, { resetForm }) => {
    console.log("device value value",values);
    setStatus(true);
    setNextButton(true);
    const res = await CreateDeviceTypeApiHandler(values);
    console.log("create device",res);
    if(res.success === true)
    {
      setIsProjectCreate(true);
      setIsError(false);
      resetForm();
    }
    else{
      setIsError(true);
      setIsProjectCreate(false);
    }

  };

  return (
    <>
     
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        return (
          <>
            
                 {isProjectCreate && (
                  <div className={styles["password-reset-email-success"]}>
                    Device Type register successful
                  </div>
                )}
                {isError && (
                  <div className={styles["password-reset-email-error"]}>
                    Device type already exists
                  </div>
                )}
                
                
                <h3 className={styles['group-header-center']}>Create Device Type</h3>
                <div className={styles["login-input-field"]}>
                
                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="deviceNameEn"
                      name="deviceNameEn"
                      label= "Device Name English"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.deviceNameEn}
                      error={
                        formik.touched.deviceNameEn &&
                        Boolean(formik.errors.deviceNameEn)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.deviceNameEn && formik.errors.deviceNameEn ? (
                        <div>{formik.errors.deviceNameEn}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>
                  <FormControl className={styles["login-form"]} fullWidth>
                    <TextField
                      id="deviceNameJp"
                      name="deviceNameJp"
                      label= "Device Name Japanese"
                      variant="outlined"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.deviceNameJp}
                      error={
                        formik.touched.deviceNameJp &&
                        Boolean(formik.errors.deviceNameJp)
                      }
                      type="text"
                    />
                    <span className={styles["error"]}>
                      {formik.touched.deviceNameJp && formik.errors.deviceNameJp ? (
                        <div>{formik.errors.deviceNameJp}</div>
                      ) : null}
                    </span>
                    <br />
                  </FormControl>
                  <FormControl className={styles["login-form"]} fullWidth>
                <div className="control">
                  <TextareaAutosize
                    id="deviceDetailsEn"
                    name="deviceDetailsEn"
                    className="input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deviceDetailsEn}
                    placeholder="Device Details English"
                    style={{ width: '99%', maxWidth: '100%',minWidth:'97%', height:70, maxHeight:70 , minHeight:70,
                             fontSize:'17px', fontWeight:"none", textIndent:'10px',border:'1px solid gray', borderRadius:'5px'}}
                  />
                </div>
              
              <br />
                  </FormControl>

                  <FormControl className={styles["login-form"]} fullWidth>
                <div className="control">
                  <TextareaAutosize
                    id="deviceDetailsJp"
                    name="deviceDetailsJp"
                    className="input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deviceDetailsJp}
                    placeholder="Device Details Japanese"
                    style={{ width: '99%', maxWidth: '100%',minWidth:'97%', height:70, maxHeight:70 , minHeight:70,
                             fontSize:'17px', fontWeight:"none", textIndent:'10px',border:'1px solid gray', borderRadius:'5px'}}
                  />
                </div>
              
              <br />
                  </FormControl>
                  
                </div>
                <Stack justifyContent="center">
                  <Button
                    type="Submit"
                    variant="contained"
                    
                    onClick={formik.handleSubmit}
                   
                  >
                   Create
                  </Button>
                </Stack>
              
          </>
        );
      }}
    </Formik>
    </>
  );
};

export default CreateDeviceType;

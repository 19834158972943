
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoginFooter from "../LoginFooter";
import LoginHeader from "../LoginHeader";
import styles from "./LoginPanel.module.css";
import LoginForm from "./LoginForm";
import WelcomeAnimation from "../WelcomeAnimation";

const Login = () => {
    
  return (
   <>
   <div className={styles["welcome-panel-wrapper"]}>
     <LoginHeader/>
     <Grid container justifyContent="center" spacing={3} mt={1} ml={10}>
          <Grid
            item
            sm={0}
            md={4}
            mt={4}
            className={styles["registration-lottie-wrapper"]}
          >
            
            <WelcomeAnimation/>
          </Grid>
          <Grid item md={6} xs={12} justifyContent="center">
            <Box
              sx={{
                marginLeft: "5px",
                marginTop: "10%"
              }}
            >
              <Box
                sx={{ width: "100%" }}
                className={styles["welcome-login-reg-side-wrapper"]}
              >
                <LoginForm/>
              </Box>

            </Box>
          </Grid>
        </Grid>
     <LoginFooter/>
   </div>

   </>
  )
}

export default Login;
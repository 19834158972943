import React, { useState } from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font
} from "@react-pdf/renderer";

Font.register({
    family: "Noto Sans JP",
    src: "https://cdn.jsdelivr.net/npm/noto-sans-japanese@1.0.0/fonts/NotoSansJP-Light.otf"
});

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: "Noto Sans JP",
    },
    gridContainer: {
        // display: 'flex',
        // flexBasis: '200px',
        justifyContent: 'space-evenly',
        justifyItems: 'center',
        alignContent: 'space-evenly',
        alignItems: 'center',
    },
    gridItem1: {
        margin: "5px",
    },
    imageSize: {
        width: "auto",
        height: "65px"
    },
    gridItem2: {
        alignItems: "center",
        // width: '50%',
        borderTop: "1px solid black",
        marginTop: 'auto'
    },
    id: {
        fontSize: "5px",
        margin: "10px"
    },
    heading: {
        justifyItems: 'center',
        alignItems: 'center',
        marginTop: '50px',
        marginBottom: '20px',
    },
    h1: {
        fontSize: "20px",
        fontWeight: 'bold',
    },
    h2: {
        fontSize: "14px",
        fontWeight: 'bold'
    }
});

const QRCodeDownload = (props) => {
    console.log('__QrCodePdfDownload starts');

    const [pdfData, setPdfData] = useState(props.pdfData);
    console.log('__QrCodePdfDownload data', pdfData);
    if (props.pdfData.length !== 0) {
        return (
            <Document>
                {/* {pdfData?.map((row, index) => */}
                <Page size="A4" style={styles.page}>
                    <View style={styles.heading}>
                        <Text style={styles.h1}>ETTMS</Text>
                        <Text style={styles.h2}>DeviceTag QR Codes</Text>
                    </View>

                    {pdfData?.map((row, index) =>
                        <View key={index} style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Image
                                    style={styles.imageSize}
                                    src={`${row.qr_code}`}
                                    alt="qr code image" />
                            </View>
                            <View style={styles.gridItem2}>
                                <Text style={styles.id}>{`${row.device_tag}`}</Text>
                            </View>
                        </View>
                    )}
                </Page>
                {/* )} */}
            </Document>
        );
    }
};

export default QRCodeDownload;
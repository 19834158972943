
import { Button, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Formik } from "formik";
import { useState } from "react";
import styles from "./SendMailToOrganization.module.css";
import { useEffect } from "react";
import sendCollaborationRequestEmailApi from "../../HandoverApi/SendCollaborationRequestMailApi";


const SendMailToOrganization = () => {

    const [isValid, setIsValid] = useState(false);
    const [isEmailSended, setIsEmailSended] = useState(false);
    const [isError, setIsError] = useState(false);
    const [groupData, setGroupData] = useState([]);
    const [permissionData, setPermissionData] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedGroupName, setSelectedGroupName] = useState([]);

    const initialValues = {
        email: ""
    };

    const validate = (values) => {
        setIsValid(true);
        let errors = {};
        // if (!values.groupName) {
        //     errors.groupName = "Group name is required";
        //     setIsValid(false);
        // }
        // if (!values.permissionName) {
        //     errors.permissionName = "Permission name is required";
        //     setIsValid(false);
        // }

        if (!values.email) {
            errors.email = "Email is required";
            setIsValid(false);
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address";
            setIsValid(false);
        }

        return errors;
    };


    const onSubmit = async (values, { resetForm }) => {

        console.log("mail value is ", values);
        const response = await sendCollaborationRequestEmailApi(values);
        console.log("mail response", response)
        if (response.success === true) {
            setIsEmailSended(true);
            setIsError(false);
            resetForm();

        } else {
            setIsError(true);
            setIsEmailSended(false);
        }

    };

    //   useEffect( () =>{
    //     const FetchGroupData = async() =>{
    //       const allData = await FetchAllGroupApi(); 
    //       console.log(allData.body);
    //        if(allData.success === true)
    //        {
    //         setGroupData(await allData.body);
    //         console.log("data aise ")

    //        }

    //     }
    //     FetchGroupData();
    //   },[])

    //   useEffect( () =>{
    //     const FetchPermissionData = async() =>{
    //       const allData = await FetchAllPermissionApi(); 
    //       console.log(allData.body);
    //        if(allData.success === true)
    //        {
    //         setPermissionData(await allData.body);
    //         console.log("data aise ")

    //        }

    //     }
    //     FetchPermissionData();
    //   },[])


    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
        >
            {(formik) => {
                return (
                    <>

                        {isEmailSended && (
                            <div className={styles["password-reset-email-success"]}>
                                Mail send successfully
                            </div>
                        )}
                        {isError && (
                            <div className={styles["password-reset-email-error"]}>
                                Something went wrong !!!!!
                            </div>
                        )}

                        <h3 className={styles['group-header-center']}>Send Mail For Collaboration</h3>

                        <div className={styles["login-input-field"]}>
                            {/* <FormControl required className={styles["login-form"]} fullWidth>
                                <InputLabel id="organization-initial-label">Group Name</InputLabel>
                                {formik.values.groupName ? setSelectedGroupId(formik.values.groupName) : null}
                                {groupData.map((i) => (
                                    selectedGroupId === i._id ? setSelectedGroupName(i.name_en) : null
                                ))}
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="groupName"
                                    name="groupName"
                                    value={formik.values.groupName}
                                    label="Group Name"
                                    size="small"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.groupName &&
                                        Boolean(formik.errors.groupName)
                                    }
                                >

                                    {groupData && groupData.map((group) => (
                                        <MenuItem
                                            key={group._id}
                                            value={group._id}
                                        >
                                            {group.name_en}
                                        </MenuItem>
                                    ))}

                                </Select>
                                <span className={styles["error"]}>
                                    {formik.touched.groupName &&
                                        formik.errors.groupName ? (
                                        <div>{formik.errors.groupName}</div>
                                    ) : null}
                                </span>



                                <br />
                            </FormControl>

                            <FormControl required className={styles["login-form"]} fullWidth>
                                <InputLabel id="organization-initial-label">Permission Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="permissionName"
                                    name="permissionName"
                                    value={formik.values.permissionName}
                                    label="Permission Name"
                                    size="small"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.permissionName &&
                                        Boolean(formik.errors.permissionName)
                                    }
                                >

                                    {permissionData && permissionData.map((permission) => (
                                        <MenuItem
                                            key={permission._id}
                                            value={permission._id}
                                        >
                                            {permission.title}
                                        </MenuItem>
                                    ))}

                                </Select>
                                <span className={styles["error"]}>
                                    {formik.touched.permissionName &&
                                        formik.errors.permissionName ? (
                                        <div>{formik.errors.permissionName}</div>
                                    ) : null}
                                </span>



                                <br />
                            </FormControl> */}


                            <FormControl className={styles["login-form"]} fullWidth>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Enter Email"
                                    variant="outlined"
                                    size="small"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    type="email"
                                />
                                <span className={styles["error"]}>
                                    {formik.touched.email && formik.errors.email ? (
                                        <div>{formik.errors.email}</div>
                                    ) : null}
                                </span>
                                <br />
                            </FormControl>
                        </div>
                        <Stack justifyContent="center">
                            <Button
                                type="Submit"
                                variant="contained"

                                onClick={formik.handleSubmit}
                            >
                                Send Mail
                            </Button>
                        </Stack>

                    </>
                );
            }}
        </Formik>
    );
};

export default SendMailToOrganization


import React from "react";
import styles from "./LoginFooter.module.css";

function LoginFooter() {

  return (
    <div className={styles["loginFooter-wrapper"]}>

      <p><span >Ultra-x asia pacific Inc. </span><span style={{ float: 'right' }}> Version 2.1.0</span></p>

    </div>
  );
}

export default LoginFooter;
const ReportRecordApiHandler = async (id , values) => {
    const accessToken = localStorage.getItem('access_token');
    console.log('tut',id);
    console.log('tut',values);
    const res = await fetch(`${process.env.REACT_APP_BASE_URL+"records/reportARecord"}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        record_id: id,
        reported_reason: values.reportDetails
      }),
      
    });
   
    const data = await res.json();
    console.log(data)
    return data;

}

export default ReportRecordApiHandler
import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styles from './HandoverSend.module.css';
import showUnApprovedHandoverApi from '../HandoverApi/ShowUnApprovedHandoverApi';
import SelectOrganizationModal from './SelectOrganizationModal';


const getDatePlease = (D) => {
    return new Date(D).toLocaleString();
}


const HandoverSend = () => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [groupData, setGroupData] = useState([]);
    const [loading, setLoading] = useState(false);

    console.log(groupData)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const FetchData = async () => {
            setLoading(true);
            const response = await showUnApprovedHandoverApi();
            console.log("unapproved handover data", response.body)
            if (response.success === true) {
                setGroupData(response.body);
                setLoading(false);
            }

        }
        FetchData()

    }, [])

    return (
        <>
            {loading === true ? (
                <h3 style={{ textAlign: 'center' }}>Loading...</h3>
            ) : groupData && groupData.length === 0 ? (
                <h3 style={{ textAlign: 'center' }}>No record Available</h3>
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                    <TableContainer sx={{ maxHeight: 345 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Handover ID</TableCell>
                                    <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Track Tag</TableCell>
                                    <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Created At</TableCell>
                                    <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Status</TableCell>
                                    <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Organization</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupData && groupData.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" align="center">
                                            {row._id}
                                        </TableCell>
                                        <TableCell align="center">{row.track_id.track_tag.tag_number}</TableCell>
                                        <TableCell align="center">{getDatePlease(row.createdAt)}</TableCell>
                                        <TableCell align="center">{row.status}<br></br>{row.status === "Request Rejected" ? ` by ${row.request_to.name_en}` : null}</TableCell>
                                        <TableCell align="center">


                                            {/* <Stack justifyContent="center" spacing={2} direction="row">
                                            <Button variant="contained" >Update</Button>
                                            
                                        </Stack> */}
                                            <SelectOrganizationModal previous_handover_id={row.Previous_handover_id} handover_id={row._id} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={groupData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            )}
        </>
    )
}

export default HandoverSend


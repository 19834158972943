import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ShowSendHandoverApi from '../HandoverApi/ShowSendHandoverApi';

const HandoverStatus = () => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [handoverSendData, setHandoverSendData] = useState([])



    /** API FOR SEND HANDOVER DATA TO OTHER ORGANIZATION START **/

    useEffect(() => {
        const fetchSendHandoverTableData = async () => {
            // const showSendData = await ShowSendHandoverAPI();
            const showSendData = await ShowSendHandoverApi()
            console.log(showSendData.body)


            if (showSendData.success === true) {
                console.log(showSendData.body)
                setHandoverSendData(showSendData.body)
            }
        }
        fetchSendHandoverTableData();
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // const handleChangeRowsPerPage = (event) => {
    //   setRowsPerPage(+event.target.value);
    //   setPage(0);
    // };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handleChangeRowsPerPage = (event) => {
    //   setRowsPerPage(parseInt(event.target.value, 10));
    //   setPage(0);
    // };

    // useEffect(() => {

    //     const fetchHandoverTableData = async () => {
    //       const showSendData = await ShowSendHandoverAPI();

    //       console.log(JSON.stringify(showSendData.body, null, 2))
    //       if (showSendData.success == true) {
    //          setHandoverSendData(showSendData.body)
    //       }
    //     }
    //     fetchHandoverTableData();
    //   }, [])


    /** API FOR SEND HANDOVER DATA TO OTHER ORGANIZATION END **/

    return (
        <>
            {/* <h2 className="track-req__h2">Show All Handover Status for Sending</h2> */}

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {/* <h1>{props.id}</h1> */}
                <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                <TableCell style={{ background: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Handover ID</TableCell>
                                <TableCell style={{ background: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Request From</TableCell>
                                <TableCell style={{ background: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Request To</TableCell>
                                <TableCell style={{ background: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Status</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {handoverSendData && handoverSendData.map((row) => (
                                <TableRow
                                    key={row._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" align="center">{row._id}</TableCell>
                                    <TableCell align="center">{row.request_by.name_en}</TableCell>
                                    <TableCell align="center">{row.request_to.name_en}</TableCell>
                                    <TableCell align="center">{row.status} </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={handoverSendData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </>
    )
}

export default HandoverStatus
